import React, { useState } from 'react';
import { Dialog, DialogTitle, List, ListItem, ListItemText, DialogActions, Button, TextField, Typography, Box } from '@mui/material';
import { Build, Print, WifiOff } from '@mui/icons-material';

const ModalNewRequest = ({ open, handleClose }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [details, setDetails] = useState("");
  const [image, setImage] = useState(null);

  const items = [
    { name: 'Rollos de papel', icon: <Print /> },
    { name: 'Asistencia técnica', icon: <Build /> },
    { name: 'Problemas de internet', icon: <WifiOff /> }
  ];


  const handleItemClick = (item) => {
    setSelectedItem(item);
    setDetails("");
    setImage(null);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Selecciona un servicio</DialogTitle>
      {items.map((item) => (
        <Box
          key={item.name}
          onClick={() => handleItemClick(item)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100px',
            height: '100px',
            borderRadius: '8px',
            cursor: 'pointer',
            border: selectedItem?.name === item.name ? '2px solid #3f51b5' : '1px solid #ccc',
            padding: '16px',
            transition: 'border 0.3s',
            "&:hover": {
              borderColor: '#3f51b5'
            }
          }}
        >
          {item.icon}
          <Typography variant="body2" sx={{ mt: 1 }}>
            {item.name}
          </Typography>
        </Box>
      ))}
      {selectedItem && (
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">Detalles para: {selectedItem.name}</Typography>
          <TextField
            label="Detalles adicionales"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Button
            variant="contained"
            component="label"
            sx={{ mt: 2 }}
          >
            Adjuntar imagen
            <input type="file" hidden onChange={handleImageUpload} />
          </Button>
          {image && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">Vista previa de la imagen:</Typography>
              <img src={image} alt="Adjunto" style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }} />
            </Box>
          )}
        </Box>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={() => console.log("Selected item:", selectedItem, "Details:", details, "Image:", image)} color="primary">
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalNewRequest;
