const formatRUT = (rut) => {
    // Remover puntos y guion del RUT
    let cleanRut = rut.replace(/[.-]/g, '');

    // Convertir a mayúsculas el dígito verificador para evitar problemas con 'k' minúscula
    cleanRut = cleanRut.slice(0, -1) + cleanRut.slice(-1).toUpperCase();

    // Verificar que el RUT no esté vacío o tenga caracteres inválidos
    if (!cleanRut || isNaN(cleanRut.slice(0, -1)) || (cleanRut.slice(-1) !== 'K' && isNaN(cleanRut.slice(-1)))) {
        return rut;
    }

    // Obtener la parte numérica y el dígito verificador
    const num = cleanRut.slice(0, -1);
    const dv = cleanRut.slice(-1);

    // Retornar el RUT formateado sin puntos, solo con guion
    return `${num}-${dv}`;
};

export default formatRUT;