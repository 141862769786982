import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { addClient, getAllClients } from '../database/clients';
import ReusableDrawer from '../components/drawer';
import { Avatar, Box, Button, Grid, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import colors from '../utils/colors';
import formatRUT from '../utils/format_rut';
import Swal from 'sweetalert2';
import loadingSVG from '../assets/loading.svg'


const ExcelClients = () => {
    const [alreadyInDb, setAlreadyInDb] = useState([]);
    const [notInDb, setNotInDb] = useState([]);
    const fileInputRef = useRef();

    const normalizeRut = (rut) => {
        if (rut !== undefined) {
            return rut.replace(/\./g, '').replace(/-/g, '');
        } else {
            return '';
        }
    };

    const readExcelFile = async (file) => {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        console.log(jsonData);
        return jsonData.map(row => ({
            rut: normalizeRut(row['Rut empresa']),
            razonSocial: row['Razón social'],
            email: row['email'] ?? null
        }));
    };

    const compareRutEnterprise = async (file) => {
        Swal.fire({
            imageUrl: loadingSVG,
            imageWidth: 300,
            imageHeight: 300,
            title: "Cargando clientes...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
        const excelData = await readExcelFile(file);
        const firebaseClients = await getAllClients();
        const firebaseRutEnterprise = firebaseClients.map(client => normalizeRut(client.rutEnterprise));

        const uniqueAlreadyInDb = new Set();
        const uniqueNotInDb = new Set();

        const alreadyInDb = [];
        const notInDb = [];

        excelData.forEach(entry => {
            if (firebaseRutEnterprise.includes(entry.rut)) {
                if (!uniqueAlreadyInDb.has(entry.rut)) {
                    uniqueAlreadyInDb.add(entry.rut);
                    alreadyInDb.push(entry);
                }
            } else {
                if (!uniqueNotInDb.has(entry.rut)) {
                    uniqueNotInDb.add(entry.rut);
                    notInDb.push(entry);
                }
            }
        });

        setAlreadyInDb(alreadyInDb);
        setNotInDb(notInDb);
        Swal.close();
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            await compareRutEnterprise(file);
        }
    };

    const addClients = async () => {
        if (notInDb.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Debe haber al menos un cliente para agregar'
            })
        } else {
            Swal.fire({
                imageUrl: loadingSVG,
                imageWidth: 300,
                imageHeight: 300,
                title: "Creando clientes...",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });

            for (const client of notInDb) {
                console.log(client);
                await addClient(
                    client.razonSocial,
                    client.rut,
                    client.email !== null && client.email !== undefined ? client.email : ''
                );
            }

            Swal.fire({
                icon: 'success',
                title: 'Realizado',
                text: 'Clientes agregados'
            })
        }
    }

    const [drawerOpen, setDrawerOpen] = useState(true);
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const showInfo = (name, rut, email) => {
        Swal.fire({
            html: `
                <h3>Información del cliente</h3>
                <p><strong>Razón social:</strong> ${name}</p>
                <p><strong>RUT:</strong> ${formatRUT(rut)}</p>
                <p><strong>Email:</strong> ${email}</p>
              `,
        })
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'start',
            height: '100vh',
            width: '100vw',
        }}>
            <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'settings'} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
            }}>
                <h2 style={{
                    color: colors.green,
                }}>Clientes por subir</h2>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleButtonClick}
                    sx={{ marginBottom: '16px' }}
                >
                    Subir archivo
                </Button>
                <input
                    type="file"
                    onChange={handleFileUpload}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                />
                <Typography variant='h5' sx={{
                    fontWeight: 'bold',
                    fontFamily: "Nunito"
                }}>
                    {notInDb.length} clientes no están en la base de datos
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    maxHeight: '65vh',
                    overflow: 'auto',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    padding: '16px',
                    width: '80%', // Ajusta esto según tus necesidades
                    marginTop: '16px'
                }}>
                    <Grid container spacing={2}>
                        {notInDb.map((data, index) => (
                            <Grid onClick={
                                () => showInfo(data.razonSocial, data.rut, data.email ?? 'No disponible')
                            } item xs={12} sm={6} md={4} key={index}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '8px',
                                    border: '1px solid #ccc',
                                    borderRadius: '8px'
                                }}>
                                    <Avatar sx={{ backgroundColor: colors.green, marginRight: '8px' }}>
                                        {data.razonSocial !== undefined ? data.razonSocial.toString().substring(0, 1) : '1'}
                                    </Avatar>
                                    <Box>
                                        <Typography sx={{
                                            fontFamily: "Nunito"
                                        }} variant="body2" fontWeight="bold">{data.razonSocial.length > 25 ? data.razonSocial.substring(0, 25) + '...' : data.razonSocial}</Typography>
                                        <Typography sx={{
                                            fontFamily: "Nunito"
                                        }} variant="body2">{formatRUT(data.rut)}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box sx={{
                    marginTop: '20px'
                }}>
                    <Button
                        onClick={() => {
                            addClients();
                        }}
                        sx={{
                            marginBottom: '16px',
                            marginLeft: '10px',
                            backgroundColor: colors.green,
                            color: colors.white
                        }}
                    >
                        Agregar estos clientes
                    </Button>
                </Box>
            </Box>
        </div>
    );
};

export default ExcelClients;
