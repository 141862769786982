import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { getClientsForDownload } from '../database/clients';
import { getAllSellers } from '../database/sellers';

const createExcel = async (withSeller) => {
    try {

        const sellers = await getAllSellers();
        // Obtener los datos de los clientes
        const clientsData = await getClientsForDownload();

        // Crear un nuevo libro de Excel
        const workbook = XLSX.utils.book_new();

        // Crear una hoja de Excel
        const worksheet = XLSX.utils.aoa_to_sheet([
            ['Fecha de ingreso', 'Tipo de persona', 'Correo empresa', 'Razón social', 'Rut empresa', 'Dirección empresa', 'Comuna empresa', 'Teléfono empresa', 'Actividad SII', 'Clave SII', 'Nombre representante', 'Rut', 'Dirección', 'Teléfono', 'Correo', 'Titular cuenta', 'Banco', 'Tipo de cuenta', 'Número cuenta', 'Tarifa débito', 'Tarifa crédito', 'Pagos', 'Boleta', 'Factura', '¿Tiene deudas?', 'Cuotas pagadas', 'Cuotas faltantes', 'Valor de cuota', 'Pie', 'Rut de vendedor', 'Nombre de vendedor', 'Sucursales', 'Cantidad de POS'],
        ]);

        console.log('AQUI FORMATEA ', formatRUT('19.768.342-2'));


        // Agregar datos a la hoja
        for (const client of clientsData) {
            const offices = client.offices || [];
            const nombresOficinas = offices.map((office) => office.address).join(', ');
            const hasDataPays = client.dataPays != null;
            let sellerData = {};
            if (withSeller) {
                sellerData = sellers.find(seller => seller.id === client.idSeller) || {};
            }
            const dateInit = client.createdAt ? new Date(client.createdAt.seconds * 1000).toLocaleDateString('es-ES') : '-';

            XLSX.utils.sheet_add_aoa(worksheet, [[
                dateInit,
                client.typePersonEnterprise || '',
                client.emailEnterprise || '',
                client.socialReason || '',
                formatRUT(client.rutEnterprise) || '',
                client.addressEnterprise || '',
                client.comuneEnterprise || '',
                client.phoneEnterprise || '',
                client.activitySIIEnterprise || '',
                client.keySIIEnterprise || '',
                client.nameRepresent || '',
                formatRUT(client.rutRepresent) || '',
                client.addressRepresent || '',
                client.phoneRepresent || '',
                client.emailRepresent || '',
                client.accountMain || '',
                client.accountBank || '',
                client.accountType || '',
                client.accountNumber || '',
                client.debit || '',
                client.credit || '',
                client.pays ? 'Sí' : 'No',
                client.invoice ? 'Sí' : 'No',
                client.bill ? 'Sí' : 'No',
                hasDataPays ? (client.dataPays.status === 'pending' && client.dataPays.type === 'cuotas' ? 'Sí' : 'No') : 'No',
                hasDataPays ? (client.dataPays.cuotas ? client.dataPays.cuotas.filter(cuota => cuota.status === 'payed').length : '0') : '0',
                hasDataPays ? (client.dataPays.cuotas ? client.dataPays.cuotas.length - client.dataPays.cuotas.filter(cuota => cuota.status === 'payed').length : '0') : '0',
                hasDataPays ? (client.dataPays.cuotas ? client.dataPays.cuotas[0].value : '0') : '0',
                hasDataPays ? (client.dataPays.pie || '-') : '0',
                withSeller ? (formatRUT(sellerData.rut) || '-') : '-',
                withSeller ? (sellerData.name || '-') : '-',
                nombresOficinas,
                offices.length > 0 ? offices.reduce((sum, office) => sum + (office.pos || 0), 0) : '0',
            ]], { origin: -1 });
        }

        // Añadir la hoja al libro
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Escribir el archivo
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/octet-stream' });

        // Guardar el archivo
        saveAs(blob, 'Clientes.xlsx');


        return true;
    } catch (error) {


        console.error(error);

        return false;
    }
};

function formatRUT(rut) {
    // Si el valor está vacío o no es una cadena, devolver tal cual
    if (typeof rut !== 'string' || rut.trim() === '') {
        return rut;
    }

    // Validar si el RUT es puramente un texto sin dígitos
    if (/^[a-zA-Z\s]+$/.test(rut)) {
        return rut; // Si contiene solo letras y espacios, lo devolvemos tal como viene
    }

    // Limpiar el RUT eliminando cualquier caracter no numérico o 'k'
    let cleanRUT = rut.replace(/[^\dkK]/g, '').toUpperCase();

    // Asegurarse de que tiene al menos 2 caracteres (dígitos + dígito verificador)
    if (cleanRUT.length < 2) return rut;

    // Separar el dígito verificador del resto
    const dv = cleanRUT.slice(-1);
    const body = cleanRUT.slice(0, -1);

    // Unir cuerpo y dígito verificador solo con guión, sin puntos
    return `${body}-${dv}`;
}

export default createExcel;
