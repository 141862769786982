import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, IconButton, Box, Container, Divider, Avatar, List, ListItem, ListItemAvatar, ListItemText, ListItemIcon } from '@mui/material';
import { Close, ExitToApp, Home, HomeOutlined, MailOutline, Person2Outlined, Person3Outlined, PersonOutline, SettingsOutlined, SupportAgent } from '@mui/icons-material';
import logo from '../assets/logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../utils/colors';
import maxipos from '../assets/spark.svg';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../database/config_firebase';
import { logout } from "../actions/auth_actions";
import { capitalize } from '../utils/capitalize';

const ReusableDrawer = ({ onClose, children, anchor = 'left', width = 300, route }) => {

  const userData = useSelector(state => state.auth.userData);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };



  return (
    <Drawer
      anchor={anchor}
      open={true}
      onClose={onClose}
      variant="permanent"
      sx={{
        zIndex: 0,
        width: width,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: width,
          boxSizing: 'border-box',
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
        },
      }}
    >
      <Container sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        mt: 2
      }}>
        {/* <IconButton onClick={onClose}>
            <Close />
        </IconButton> */}
        <img src={logo} width={'100%'} alt="Logo" />
      </Container>
      <Divider variant="middle" />
      <ListItem sx={{
        mt: 2,
        ml: 2,
        border: '1px solid #1212121F',
        backgroundColor: '#F7F7F8',
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'row',
        width: '90%',
        borderRadius: '10px'
      }}>
        <ListItemAvatar>
          <Avatar sx={{
            border: '1px solid #1212121F',
          }} src={
            maxipos
          } />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ fontWeight: 'bold' }}
          primary={userData && userData.name ? userData.name : 'Admin'}
          secondary={userData && userData.type ? capitalize(userData.type) : ''}
        />
      </ListItem>
      <List sx={{ mt: 2, flex: 1, justifyContent: 'flex-start' }}>
        <Container>
          <ListItem
            onClick={() => {
              navigate('/home');
            }}
            sx={{
              borderRadius: '10px',
              fontWeight: 'bold',
              color: route === 'home' ? colors.green : 'black',
              backgroundColor: route === 'home' ? colors.greenBackground : 'transparent',
              border: route === 'home' ? `1px solid ${route === 'home' ? colors.green : 'transparent'}` : 'none',
              p: 2,
              mt: 1
            }} button>
            <ListItemIcon>
              <HomeOutlined sx={{
                color: route === 'home' ? colors.green : 'black',

              }} />
            </ListItemIcon>
            Inicio
          </ListItem>
          <ListItem
            onClick={() => {
              navigate('/sellers');
            }}
            sx={{
              borderRadius: '10px',
              p: 2,
              mt: 1,
              fontWeight: 'bold',
              color: route === 'sellers' ? colors.green : 'black',
              backgroundColor: route === 'sellers' ? colors.greenBackground : 'transparent',
              border: route === 'sellers' ? `1px solid ${route === 'sellers' ? colors.green : 'transparent'}` : 'none',
            }} button>
            <ListItemIcon>
              <Person3Outlined sx={{
                color: route === 'sellers' ? colors.green : 'black',
              }} />
            </ListItemIcon>
            Vendedores
          </ListItem>
          <ListItem
            onClick={() => {
              navigate('/clients');
            }}
            sx={{
              borderRadius: '10px',
              p: 2,
              mt: 1,
              fontWeight: 'bold',
              color: route === 'clients' ? colors.green : 'black',
              backgroundColor: route === 'clients' ? colors.greenBackground : 'transparent',
              border: route === 'clients' ? `1px solid ${route === 'clients' ? colors.green : 'transparent'}` : 'none',
            }} button>
            <ListItemIcon>
              <PersonOutline sx={{
                color: route === 'clients' ? colors.green : 'black',

              }} />
            </ListItemIcon>
            Clientes
          </ListItem>
          {/* <ListItem
            onClick={() => {
              navigate('/debtorClients');
            }}
            sx={{
              borderRadius: '10px',
              p: 2,
              mt: 1,
              fontWeight: 'bold',
              color: route === 'debtors' ? colors.green : 'black',
              backgroundColor: route === 'debtors' ? colors.greenBackground : 'transparent',
              border: route === 'debtors' ? `1px solid ${route === 'debtors' ? colors.green : 'transparent'}` : 'none',
            }} button>
            <ListItemIcon>
              <Person2Outlined sx={{
                color: route === 'debtors' ? colors.green : 'black',

              }} />
            </ListItemIcon>
            Clientes morosos
          </ListItem> */}
          <ListItem
            onClick={() => {
              navigate('/emails');
            }}
            sx={{
              borderRadius: '10px',
              p: 2,
              mt: 1,
              fontWeight: 'bold',
              color: route === 'emails' ? colors.green : 'black',
              backgroundColor: route === 'emails' ? colors.greenBackground : 'transparent',
              border: route === 'emails' ? `1px solid ${route === 'emails' ? colors.green : 'transparent'}` : 'none',
            }} button>
            <ListItemIcon>
              <MailOutline sx={{
                color: route === 'emails' ? colors.green : 'black',

              }} />
            </ListItemIcon>
            Correos
          </ListItem>
          <ListItem
            onClick={() => {
              navigate('/settings');
            }}
            sx={{
              borderRadius: '10px',
              p: 2,
              mt: 1,
              fontWeight: 'bold',
              color: route === 'settings' ? colors.green : 'black',
              backgroundColor: route === 'settings' ? colors.greenBackground : 'transparent',
              border: route === 'settings' ? `1px solid ${route === 'settings' ? colors.green : 'transparent'}` : 'none',
            }} button>
            <ListItemIcon>
              <SettingsOutlined sx={{
                color: route === 'settings' ? colors.green : 'black',
              }} />
            </ListItemIcon>
            Ajustes
          </ListItem>
        </Container>
        <Container style={{
          position: 'absolute',
          bottom: 10,
          width: '100%'
        }}>
          {/* <ListItem button
            onClick={()=>{
              navigate('/support');
            }}
            sx={{
              border: route === 'support' ? `1px solid ${route === 'support' ? colors.green : 'transparent'}` : 'none',
              borderRadius: '10px',
              p: 2,
              fontWeight: 'bold',
              backgroundColor: route === 'support' ? colors.greenBackground : 'transparent',
              color: route === 'support' ? colors.green : 'black'
            }}>
            <ListItemIcon>
              <SupportAgent sx={{
                color: route === 'support' ? colors.green : 'black',

              }} />
            </ListItemIcon>
            Soporte técnico
          </ListItem> */}
          <Divider />
          <ListItem button
            onClick={handleLogout}
            sx={{
              borderRadius: '10px',
              p: 2,
              fontWeight: 'bold',
              color: 'black',
            }}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            Cerrar sesión
          </ListItem>
        </Container>
      </List>
    </Drawer>
  );
};

ReusableDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  width: PropTypes.number,
};

export default ReusableDrawer;
