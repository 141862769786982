import {
    Dialog, DialogActions, DialogContent, DialogTitle, Typography,
    List,
    ListItem,
    ListItemText,
    Paper,
    Button,
    makeStyles
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import formatToClp from '../utils/format_to_clp';
import colors from '../utils/colors';
import { payPie, payQuota } from '../database/requests';
import Swal from 'sweetalert2';
import loadingSVG from '../assets/loading.svg';

export default function ModalPay({ isOpen, data, onClose, onCloseMain, reloadData }) {
    const now = new Date();
    const [totalAmount, setTotalAmount] = useState(0);
    const quotaItemStyle = (isPayed, quotaDate) => ({
        backgroundColor: isPayed ? 'rgba(76, 175, 80, 0.8)' : now > quotaDate ? '#f44336' : '#fff',
        borderRadius: '12px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '16px',
        marginBottom: '16px',
        color: isPayed || now > quotaDate ? '#fff' : '#000',
    });

    const quotaTextStyle = (isPayed, quotaDate) => ({
        color: isPayed || now > quotaDate ? '#fff' : 'rgba(0, 0, 0, 0.5)',
    });

    const pay = async () => {
        Swal.fire({
            imageUrl: loadingSVG,
            imageWidth: 300,
            imageHeight: 300,
            title: "Pagando cuota...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass:{
                popup: 'swal-overlay'
            }
        });
        const response = await payQuota(data.id);
        if (response) {
            onClose();
            Swal.close();
            Swal.fire({
                icon: "success",
                confirmButtonText: "Aceptar",
                title: "Cuota pagada",
                text: "La cuota ha sido pagada exitosamente.",
                customClass:{
                    popup: 'swal-overlay'
                }
            }).then(() => {
                onCloseMain();
                reloadData();
            });
        } else {
            Swal.close();
            Swal.fire({
                icon: "error",
                confirmButtonText: "Aceptar",
                title: "Oops...",
                text: "Ocurrió un error al pagar la cuota. Inténtalo nuevamente.",
                customClass:{
                    popup: 'swal-overlay'
                }
            });
        }
    }

    const payPiefunc = async () => {
        Swal.fire({
            imageUrl: loadingSVG,
            imageWidth: 300,
            imageHeight: 300,
            title: "Pagando pie...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            customClass: {
                popup: 'swal-overlay'
            }
        });
        const response = await payPie(data.id);
        if (response) {
            onClose();
            Swal.close();
            Swal.fire({
                icon: "success",
                confirmButtonText: "Aceptar",
                title: "Pie pagado",
                text: "El pie ha sido pagado exitosamente.",
                customClass:{
                    popup: 'swal-overlay'
                }
            }).then(() => {
                onCloseMain();
                reloadData();
            });;
        } else {
            Swal.close();
            Swal.fire({
                icon: "error",
                confirmButtonText: "Aceptar",
                title: "Oops...",
                text: "Ocurrió un error al pagar el pie. Inténtalo nuevamente.",
                customClass:{
                    popup: 'swal-overlay'
                }
            });
        }
    }

    useEffect(() => {
        const totalCuotas = data.dataPays.cuotas.reduce(
            (previousValue, element) =>
                previousValue +
                parseInt(element.value.toString().replaceAll('.', '').replaceAll(' ', '')),
            0
        );

        const pieAmount = parseInt(data.dataPays.pie.toString().replaceAll('.', '').replaceAll(' ', ''));

        setTotalAmount(totalCuotas + pieAmount);
    }, [data]);

    const formatDate = (date) => {
        const d = new Date(date);
        const day = (`0${d.getDate()}`).slice(-2);
        const month = (`0${d.getMonth() + 1}`).slice(-2);
        const year = d.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            sx={{
                zIndex: 1
            }}
            PaperProps={{
                style: {
                    borderRadius: '20px',
                    boxShadow: '0px 0px 20px #0000001A',
                    backgroundColor: colors.background
                }
            }}
            fullWidth={true}
            maxWidth={"md"}>
            <DialogTitle>Cuotas de {data.fantasyName ?? data.socialReason}</DialogTitle>
            <DialogContent>
                <Typography variant="body1">Monto total: {formatToClp(totalAmount)}</Typography>
                <Typography variant="body1">Pie: {formatToClp(parseInt(data.dataPays.pie.replace(/\./g, '').replace(/\s/g, '')))}</Typography>
                <Typography variant="body1">Estado de pago de pie: {data.dataPays.piePayed === 'pending' ? 'Pendiente' : 'Pagado'}</Typography>

                <List>
                    {data.dataPays.cuotas.map((quota, index) => {
                        const quotaDate = quota.date;
                        const isPayed = quota.status === 'payed';
                        return (
                            <ListItem key={index} sx={quotaItemStyle(isPayed, quotaDate)}>
                                <ListItemText
                                    primary={`Cuota ${index + 1}`}
                                    secondary={
                                        <>
                                            <Typography variant="body2" sx={quotaTextStyle(isPayed, quotaDate)}>
                                                Valor: {formatToClp(quota.value)}
                                            </Typography>
                                            <Typography variant="body2" sx={quotaTextStyle(isPayed, quotaDate)}>
                                                Fecha de cobro: {formatDate(quotaDate.toDate())}
                                            </Typography>
                                            {isPayed && (
                                                <Typography sx={{
                                                    color: colors.white
                                                }}>
                                                    Pagada el día: {formatDate(quota.datePayed.toDate())}
                                                </Typography>
                                            )}
                                        </>
                                    }
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button sx={{
                    textTransform: 'none',
                    color: colors.black,
                    fontSize: '16px'
                }} onClick={onClose} color="primary">
                    Cerrar
                </Button>
                { data.dataPays.status === 'pending' && 
                    <Button
                        sx={{
                            textTransform: 'none',
                            color: colors.green,
                            fontSize: '16px'
                        }}
                        onClick={() => {
                            Swal.fire({
                                title: '¿Estás seguro?',
                                text: 'Se pagará la primera cuota pendiente.',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: colors.green,
                                cancelButtonColor: colors.red,
                                confirmButtonText: 'Sí, pagar cuota',
                                cancelButtonText: 'Cancelar',
                                customClass:{
                                    popup: 'swal-overlay'
                                }
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    pay();
                                }else{
                                    Swal.close();
                                }
                            });
                        }} color="primary">
                        Pagar cuota
                    </Button>
                }
                {
                    data.dataPays.pie && data.dataPays.piePayed === 'pending' && (
                        <Button
                            sx={{
                                textTransform: 'none',
                                color: colors.green,
                                fontSize: '16px'
                            }}
                            onClick={() => {
                                Swal.fire({
                                    title: '¿Estás seguro?',
                                    text: 'Una vez pagado el pie, no podrás deshacer esta acción.',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: colors.green,
                                    cancelButtonColor: colors.red,
                                    confirmButtonText: 'Sí, pagar pie',
                                    cancelButtonText: 'Cancelar',
                                    customClass:{
                                        popup: 'swal-overlay'
                                    }
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        payPiefunc();
                                    }else{
                                        Swal.close();
                                    }
                                });

                            }} color="primary">
                            Pagar pie
                        </Button>)
                }
            </DialogActions>
        </Dialog>
    )
}
