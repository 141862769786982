import { Box, Button, Icon, InputAdornment, Snackbar, TextField, Typography, styled } from '@mui/material';
import React, { useState } from 'react';
import colors from '../utils/colors';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Container = styled(Box)(({ theme }) => ({
  padding: '5px 3%',
}));

const Label = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.5)',
  fontSize: '11px',
  marginBottom: '5px',
}));

const TextFieldContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.12)',
  borderRadius: '10px',
  padding: '10px',
  cursor: 'pointer' // Asegura que el cursor sea pointer
}));


const CustomTextField = ({ label, controller, icon, unlocked }) => {

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCopy = () => {
    setSnackbarOpen(true);
  };

  return (
    <Container>
      <Label sx={{
        color: 'black',
        fontFamily: 'Nunito'
      }} component="p">{label}</Label>
      {unlocked === false ? (
        <CopyToClipboard text={controller.value} onCopy={handleCopy}>
          <TextFieldContainer>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>{icon}</Icon>
                  </InputAdornment>
                ),
                disableUnderline: true,
                inputProps: {
                  readOnly: true, // Asegura que el campo no sea editable
                  style: { cursor: unlocked ? 'pointer' : 'not-allowed', color: unlocked ? 'inherit' : 'rgba(0, 0, 0, 0.38)' }
                }
              }}
              placeholder={label}
              variant="standard"
              value={controller.value}
              onChange={(e) => unlocked && controller.setValue(e.target.value)}
              sx={{
                pointerEvents: unlocked ? 'auto' : 'none',
                // backgroundColor: unlocked ? 'inherit' : 'rgba(0, 0, 0, 0.0)'
              }}
            />
          </TextFieldContainer>
        </CopyToClipboard>
      ) : (
        <TextFieldContainer>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>{icon}</Icon>
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
            placeholder={label}
            variant="standard"
            value={controller.value}
            onChange={(e) => unlocked && controller.setValue(e.target.value)}
          />
        </TextFieldContainer>
      )}
      < Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: colors.green,
            color: 'white',
            fontFamily: 'Nunito'
          }
        }}
        message={`Texto copiado al portapapeles`}
        action={
          <Button color="inherit" size="small" onClick={handleSnackbarClose}>
            Cerrar
          </Button>
        }
      />
    </Container>
  );
};

export default CustomTextField;
