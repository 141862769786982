const colors = {
    green: '#0C9A41',
    greenBackground: '#0C9A4133',
    border: '#1212121F',
    grey: '#e3e2e3',
    background: '#f6f6f8',
    red: '#BD0000',
    orange: 'orange',
    white: 'white',
    black: 'black',
}

export default colors;