import './App.css';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Login from './pages/login';
import RecoverPass from './pages/recover_pass';
import Sellers from './pages/sellers';
import Settings from './pages/settings';
import Clients from './pages/clients';
import DebtorClients from './pages/debtor_clients';
import { useSelector } from 'react-redux';
import Emails from './pages/emails';
import ExcelClients from './pages/excel_clients';
import SupportPage from './pages/support';

function App() {

  const logged = useSelector((state) => state.auth.logged);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={logged ? <Home /> : <Login />} />
        <Route path="/home" element={logged ? <Home /> : <Login />} />
        <Route path="/emails" element={logged ? <Emails /> : <Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/recover" element={<RecoverPass />} />
        <Route path="/sellers" element={logged ? <Sellers /> : <Login />} />
        <Route path="/settings" element={logged ? <Settings /> : <Login />} />
        <Route path="/clients" element={logged ? <Clients /> : <Login />} />
        <Route path="/debtorClients" element={logged ? <DebtorClients /> : <Login />} />
        <Route path="/excelClients" element={logged ? <ExcelClients /> : <Login />} />
        <Route path="/support" element={logged ? <SupportPage /> : <Login />} />


      </Routes>
    </BrowserRouter>
  );
}

export default App;
