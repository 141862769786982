import React, { useEffect, useState } from 'react';
import ReusableDrawer from '../components/drawer';
import { Box, Container, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import colors from '../utils/colors';
import { getCuotasDebtors, getPieDebtors } from '../database/clients';
import { useSelector } from 'react-redux';
import loadingImg from '../assets/loading.svg';
import { capitalize } from '../utils/capitalize';
import ModalViewClient from '../components/modal_view_client';
import { getAllSellers } from '../database/sellers';

export default function DebtorsClients() {

  const [drawerOpen, setDrawerOpen] = useState(true);
  const [clients, setClients] = useState([]);
  const myId = useSelector(state => state.auth.idUser);
  const [openModal, setOpenModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [sellers, setSellers] = useState([])

  const [selectedType, setSelectedType] = useState('cuotas');
  const [loading, setLoading] = useState(true);



  async function getRequests(type) {
    setLoading(true);
    const response = type === 'cuotas' ? await getCuotasDebtors(myId) : await getPieDebtors(myId);
    const sellersResponse = await getAllSellers();
    setSellers(sellersResponse);
    setClients(response);
    setLoading(false);
  }

  useEffect(() => {
    getRequests(selectedType);
  }, [selectedType]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'start',
      height: '100vh',
      width: '100vw',
    }}>
      <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'debtors'} />
      {loading ? <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'

      }}>
        <img src={loadingImg} alt="loading" style={{ width: '100px', height: '100px' }} />
        <p>Cargando clientes morosos...</p>
      </Box> :

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
        }}>
          <h2 style={{
            color: colors.green,
          }}>Clientes morosos</h2>
          <Container sx={{
            display: 'flex',
            flexDirection: 'row',
          }}>
            <Container
              onClick={() => setSelectedType('cuotas')}
              sx={{
                fontFamily: 'Nunito',
                cursor: 'pointer',
                border: `1px solid ${selectedType === 'cuotas' ? colors.green : '#1212121F'}`,
                borderRadius: '10px',
                backgroundColor: selectedType === 'cuotas' ? colors.greenBackground : '#1212121F',
                textAlign: 'center',
                color: selectedType === 'cuotas' ? colors.green : 'black',
                m: 1
              }}>
              <Typography sx={{
                fontFamily: 'Nunito'
              }} variant="h6">Cuotas</Typography>
            </Container>
            <Container
              onClick={() => setSelectedType('pie')}
              sx={{
                cursor: 'pointer',
                border: `1px solid ${selectedType === 'pie' ? colors.green : '#1212121F'}`,
                borderRadius: '10px',
                backgroundColor: selectedType === 'pie' ? colors.greenBackground : 'white',
                textAlign: 'center',
                color: selectedType === 'pie' ? colors.green : 'black',
                m: 1
              }}>
              <Typography sx={{
                fontFamily: 'Nunito'

              }} variant="h6">Pie</Typography>
            </Container>
          </Container>
          {clients.length === 0 ? <Box>
            <h2>No se han encontrado clientes.</h2>
          </Box> :
            clients.map((request) => (
              <ListItem
                onClick={() => {
                  setSelectedClient(request);
                  setOpenModal(true);
                }}
                key={request.id} sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '90%',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '10px',
                  border: '1px solid #1212121F',
                  margin: '2px'
                }}>
                <ListItemAvatar>
                  <img src={request.restaurant} alt="Logo" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                </ListItemAvatar>
                <ListItemText sx={{
                  ml: 1
                }}
                  primaryTypographyProps={{
                    fontFamily: 'Nunito'
                  }}
                  secondaryTypographyProps={{
                    fontFamily: 'Nunito'

                  }}
                  primary={request.fantasyName != null ? request.fantasyName : request.socialReason} secondary={`Vendedor a cargo: ${sellers.
                    find(seller => seller.id === request.idSeller).name
                    }, ${capitalize(request.addressEnterprise)}`} />
              </ListItem>
            ))
          }
        </Box>
      }
      {
        openModal && <ModalViewClient isOpen={openModal} reloadData={() => {
          getRequests(selectedType);
        }} onClose={() => setOpenModal(false)} data={selectedClient} nameSeller={
          sellers.find(seller => seller.id === selectedClient.idSeller).name
        } isPays={true} />
      }
    </div>
  )
}
