import React, { useEffect, useState } from 'react'
import ReusableDrawer from '../components/drawer';
import { Avatar, Box, Container, Fab, IconButton, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material';
import colors from '../utils/colors';
import { getMySellers } from '../database/sellers';
import { useSelector } from 'react-redux';
import imgMaxipos from '../assets/iso.svg';
import { Add, ArrowForwardIos, Delete } from '@mui/icons-material';
import loadingImg from '../assets/loading.svg';
import ModalNewSeller from '../components/modal_new_seller';
import ModalViewSeller from '../components/modal_view_seller';
import { capitalize } from '../utils/capitalize';

export default function Sellers() {

  const [drawerOpen, setDrawerOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [openViewSeller, setOpenViewSeller] = useState(false);
  const [selectedSeller, setSelectedSeller] = useState({});


  const idUser = useSelector(state => state.auth.idUser);
  const [loading, setLoading] = useState(true);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  }




  useEffect(() => {
    async function getRequests() {
      setLoading(true);
      const response = await getMySellers(idUser);
      setRequests(response);
      setLoading(false);
    }
    getRequests();
  }, [])


  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'start',
      height: '100vh',
      width: '100vw',
    }}>
      <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'sellers'} />

      {loading ? <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'

      }}>
        <img src={loadingImg} alt="loading" style={{ width: '100px', height: '100px' }} />
        <p>Cargando mis vendedores...</p>
      </Box> :
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
        }}>
          <h2 style={{
            color: colors.green,
          }}>Mis vendedores</h2>
          {
            requests.map((seller) => (
              <ListItem 
              onClick={()=>{
                setSelectedSeller(seller);
                setOpenViewSeller(true);
              }}
              key={seller.id} sx={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '90%',
                backgroundColor: '#f5f5f5',
                borderRadius: '10px',
                border: '1px solid #1212121F',
                margin: '5px'
              }}>
                <ListItemAvatar >
                  <Avatar sx={{
                    border: '1px solid #1212121F',
                  }} src={seller.image} alt="Logo" />
                </ListItemAvatar>
                <ListItemText sx={{
                  ml: 1
                }} 
                primaryTypographyProps={{
                  fontFamily: 'Nunito'
                }}
                secondaryTypographyProps={{
                  fontFamily: 'Nunito'
                
                }}
                primary={capitalize(seller.name ?? "")} secondary={capitalize(seller.email ?? "")} />
                <ListItemIcon >
                  <ArrowForwardIos />
                </ListItemIcon>
                {/* <ListItemSecondaryAction>
                  <ArrowForwardIos />
              </ListItemSecondaryAction> */}
              </ListItem>
            ))
          }
          <Fab
            aria-label="add"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              backgroundColor: colors.green,
              borderRadius: '20px'
            }}
            onClick={() => setIsOpen(true)}
          >
            <Add sx={{
              color: 'white'
            }} />
          </Fab>
          {
            isOpen && <ModalNewSeller isOpen={isOpen} onClose={() => {
              toggleModal();
            }} />
          }
          {
            openViewSeller && <ModalViewSeller isOpen={openViewSeller} data={selectedSeller} onClose={() => {
              setOpenViewSeller(false);
            }} />
          }
        </Box>
      }
    </div>
  )
}
