import React, { useEffect, useState } from 'react'
import ReusableDrawer from '../components/drawer';
import { Box, Container, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import colors from '../utils/colors';
import { AttachMoney, Download, Mail, Money, UploadFile } from '@mui/icons-material';
import Swal from 'sweetalert2';
import createExcel from '../utils/generate_excel';
import { getActualEmail, getValuePos, updateActualEmail, updateValuePos } from '../database/values';
import handleFileChange from '../utils/upload_excel';
import { useNavigate } from 'react-router-dom';
import { updateOldUsersDate } from '../database/clients';


import loadingSVG from '../assets/loading.svg'


export default function Settings() {

  const [posValue, setPosValue] = useState(0);
  const [actualEmail, setActualEmail] = useState("");


  const [usersExcel, setUsersExcel] = useState([]);
  const [usersGoodFormat, setUsersGoodFormat] = useState([]);
  const [usersBadFormat, setUsersBadFormat] = useState([]);
  const [loading, setLoading] = useState(false);


  const navigate = useNavigate();

  const formatter = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
    minimumFractionDigits: 0
  });

  async function getData() {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando datos...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    const response = await getValuePos();
    const emailUpdated = await getActualEmail();
    setActualEmail(emailUpdated)
    setPosValue(response);
    Swal.close();
  }

  useEffect(() => {

    getData();
  }, []);

  const [drawerOpen, setDrawerOpen] = useState(true);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // const update = async () => {
  //   try {
  //     const res = await updateOldUsersDate();
  //     console.log(res);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  async function changeValuePos() {
    const { value: newValue } = await Swal.fire({
      title: `Cambiar valor de equipo \n(Actual ${formatter.format(posValue)})`,
      html: `
        <input type="text" id="input-value" class="swal2-input" placeholder="Ingresa el nuevo valor">
      `,
      showCancelButton: true,
      confirmButtonText: 'Cambiar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: false,
      confirmButtonColor: colors.green,
      cancelButtonColor: colors.red,
      preConfirm: () => {
        const input = document.getElementById('input-value');
        const rawValue = input.value.replace(/[^\d]/g, ''); // Eliminar cualquier carácter no numérico
        if (!rawValue) {
          Swal.showValidationMessage('Debes ingresar un valor');
        }
        return rawValue;
      },
      didOpen: () => {
        const input = document.getElementById('input-value');
        input.setAttribute('maxlength', '10');
        input.addEventListener('input', () => {
          const rawValue = input.value.replace(/[^\d]/g, ''); // Eliminar cualquier carácter no numérico
          if (rawValue) {
            const formatter = new Intl.NumberFormat('es-CL', {
              style: 'currency',
              currency: 'CLP',
              minimumFractionDigits: 0
            });
            input.value = formatter.format(rawValue);
          } else {
            input.value = '';
          }
        });

        // Eliminar los caracteres no numéricos al hacer clic en el campo de entrada
        input.addEventListener('focus', () => {
          input.value = input.value.replace(/[^\d]/g, '');
        });

        // Re-formatear el valor al salir del campo de entrada
        input.addEventListener('blur', () => {
          const rawValue = input.value.replace(/[^\d]/g, '');
          if (rawValue) {
            const formatter = new Intl.NumberFormat('es-CL', {
              style: 'currency',
              currency: 'CLP',
              minimumFractionDigits: 0
            });
            input.value = formatter.format(rawValue);
          }
        });
      }
    });

    if (newValue) {

      const formattedValue = formatter.format(newValue);
      newValue.replace(/[^\d]/g, ''); // Eliminar cualquier carácter no numérico

      const response = await updateValuePos(parseInt(newValue));
      if (response) {
        setPosValue(newValue);
        Swal.fire({
          title: `El valor de equipo ha cambiado a: ${formattedValue}`,
          text: '',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: colors.green,
          icon: 'success'
        });
      }
    } else {
      Swal.fire('Cambio cancelado', '', 'info');
    }
  }

  async function changeActualEmailValue() {
    const { value: newValue } = await Swal.fire({
      title: `Cambiar correo de creación \n(${actualEmail})`,
      html: `
        <input type="email" id="input-value" class="swal2-input" placeholder="Ingresa el nuevo correo">
      `,
      showCancelButton: true,
      confirmButtonText: 'Cambiar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: false,
      confirmButtonColor: colors.green,
      cancelButtonColor: colors.red,
      preConfirm: () => {
        const input = document.getElementById('input-value');
        const rawValue = input.value.trim();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!rawValue) {
          Swal.showValidationMessage('Debes ingresar un correo');
        } else if (!emailRegex.test(rawValue)) {
          Swal.showValidationMessage('Debes ingresar un correo válido');
        }

        return rawValue;
      },
      didOpen: () => {
        const input = document.getElementById('input-value');

        input.addEventListener('input', () => {
          const rawValue = input.value.trim();
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

          if (emailRegex.test(rawValue)) {
            input.style.borderColor = 'green';
          } else {
            input.style.borderColor = 'red';
          }
        });
      }
    });

    if (newValue) {
      console.log(newValue);

      const response = await updateActualEmail(newValue);
      if (response) {
        setPosValue(newValue);
        getData();
        Swal.fire({
          title: `El correo ha cambiado a: ${newValue}`,
          text: '',
          confirmButtonText: 'Aceptar',
          confirmButtonColor: colors.green,
          icon: 'success'
        });
      }
    } else {
      Swal.fire('Cambio cancelado', '', 'info');
    }
  }




  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'start',
      height: '100vh',
      width: '100vw',
      backgroundColor: colors.background
    }}>
      <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'settings'} />

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
      }}>
        <h2 style={{
          color: colors.green,
        }}>Ajustes</h2>
        <Container sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <ListItem
            onClick={() => {
              Swal.fire({
                title: '¿Deseas descargar los clientes en formato excel?',
                text: "Se descargará un archivo con todos los clientes registrados en el sistema",
                showDenyButton: true,
                confirmButtonText: `Descargar`,
                denyButtonText: `Cancelar`,
                confirmButtonColor: colors.green,
                denyButtonColor: colors.red
              }).then((result) => {
                if (result.isConfirmed) {
                  createExcel(true);
                } else if (result.isDenied) {
                  Swal.fire('Descarga cancelada', '', 'info')
                }
              })
            }}
            sx={{
              backgroundColor: colors.white,
              border: `1px solid ${colors.border}`,
              borderRadius: '10px'
            }} button>
            <ListItemText primaryTypographyProps={{
              fontFamily: 'Nunito'
            }} primary="Descargar clientes en formato Excel" />
            <ListItemIcon>
              <Download sx={{}} />
            </ListItemIcon>
          </ListItem>

          {/* <ListItem sx={{
            backgroundColor: colors.grey,
            border: `1px solid ${colors.border}`,
            borderRadius: '10px',
            mt: 2
          }}
            onClick={() => {
              Swal.fire({
                allowOutsideClick: false,
                title: 'Subir clientes desde un archivo Excel',
                text: 'Selecciona un archivo Excel para subir los clientes al sistema',
                input: 'file',
                confirmButtonText: 'Subir',
                confirmButtonColor: colors.green,
                showLoaderOnConfirm: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  const file = result.value;
                  handleFileChange(file,
                    setUsersExcel,
                    setUsersGoodFormat,
                    setUsersBadFormat,
                    setLoading 
                  );
                } else {
                  Swal.fire('Subida cancelada', '', 'info')
                }
              })
            }}
            button>
            <ListItemText primaryTypographyProps={{
              color: colors.border,
              fontFamily: 'Nunito'
            }} primary="Subir clientes desde un archivo Excel" />
            <ListItemIcon>
              <UploadFile sx={{
                color: colors.border
              }} />
            </ListItemIcon>
          </ListItem> */}

          <ListItem
            onClick={() => {
              changeValuePos();
            }}
            sx={{
              backgroundColor: colors.white,
              border: `1px solid ${colors.border}`,
              borderRadius: '10px',
              mt: 2
            }} button>
            <ListItemText primaryTypographyProps={{
              fontFamily: 'Nunito'
            }} primary="Cambiar valor de equipo" />
            <ListItemIcon>
              <AttachMoney sx={{}} />
            </ListItemIcon>
          </ListItem>

          <ListItem
            onClick={() => {
              changeActualEmailValue();
            }}
            sx={{
              backgroundColor: colors.white,
              border: `1px solid ${colors.border}`,
              borderRadius: '10px',
              mt: 2
            }} button>
            <ListItemText primaryTypographyProps={{
              fontFamily: 'Nunito'
            }} primary="Cambiar correo de creación" />
            <ListItemIcon>
              <Mail sx={{}} />
            </ListItemIcon>
          </ListItem>

          <ListItem
            onClick={() => {
              navigate('/excelClients');
            }}
            sx={{
              backgroundColor: colors.white,
              border: `1px solid ${colors.border}`,
              borderRadius: '10px',
              mt: 2
            }} button>
            <ListItemText primaryTypographyProps={{
              fontFamily: 'Nunito'
            }} primary="Subir clientes desde Excel" />
            <ListItemIcon>
              <AttachMoney sx={{}} />
            </ListItemIcon>
          </ListItem>

          {/* <ListItem
            onClick={() => {
              update();
            }}
            sx={{
              backgroundColor: colors.white,
              border: `1px solid ${colors.border}`,
              borderRadius: '10px',
              mt: 2
            }} button>
            <ListItemText primaryTypographyProps={{
              fontFamily: 'Nunito'
            }} primary="Update" />
            <ListItemIcon>
              <AttachMoney sx={{}} />
            </ListItemIcon>
          </ListItem> */}

        </Container>
      </Box>
    </div>
  )
}
