import React, { useEffect, useState } from 'react'
import ReusableDrawer from '../components/drawer';
import { Box, Container, ListItem, ListItemAvatar, ListItemIcon, ListItemText } from '@mui/material';
import colors from '../utils/colors';
import { getPendingRequests } from '../database/requests';
import loadingImg from '../assets/loading.svg';
import { ArrowForwardIos } from '@mui/icons-material';
import ModalAcceptClient from '../components/modal_accept_client';
import { getAllSellers } from '../database/sellers';
import { get } from 'firebase/database';

export default function Home() {

  const [drawerOpen, setDrawerOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [sellers, setSellers] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState({});


  async function getRequests() {
    setLoading(true);
    const response = await getPendingRequests();
    const sellersResponse = await getAllSellers();
    setSellers(sellersResponse);
    setRequests(response);
    setLoading(false);
  }

  useEffect(() => {
    getRequests();
  }, [])


  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };


  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'start',
      height: '100vh',
      width: '100vw',
    }}>
      <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'home'} />
      {loading ? <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'

      }}>
        <img src={loadingImg} alt="loading" style={{ width: '100px', height: '100px' }} />
        <p>Cargando solicitudes...</p>
      </Box> :
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
        }}>
          <h2 style={{
            color: colors.green,
          }}>Solicitudes pendientes</h2>
          {
            requests.map((request) => (
              <ListItem
                onClick={() => {
                  setSelectedRequest(request);
                  setOpenModal(true);
                }}
                key={request.id} sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '90%',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '10px',
                  border: '1px solid #1212121F',
                  margin: '5px'
                }}>
                <ListItemAvatar>
                  <img src={request.restaurant} alt="Logo" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                </ListItemAvatar>
                <ListItemText sx={{
                  ml: 1
                }}
                  primaryTypographyProps={{
                    fontFamily: 'Nunito'
                  }}
                  secondaryTypographyProps={{
                    fontFamily: 'Nunito'

                  }}
                  primary={request.fantasyName != null ? request.fantasyName : request.socialReason} secondary={`Vendedor a cargo: ${sellers.find(seller => seller.id === request.idSeller).name}, ${request.addressEnterprise}, ${request.comuneEnterprise}`} />
                <ListItemIcon>
                  <ArrowForwardIos />
                </ListItemIcon>

              </ListItem>
            ))
          }
        </Box>
      }
      {
        openModal && <ModalAcceptClient reloadData={() => {
          getRequests();
        }} isOpen={openModal} data={selectedRequest} nameSeller={sellers.find(seller => seller.id === selectedRequest.idSeller).name} onClose={() => setOpenModal(false)} />
      }
    </div>
  )
}
