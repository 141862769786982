import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, MenuItem, Select, Avatar, Pagination, TextField, Button, TextareaAutosize, InputAdornment } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ReusableDrawer from '../components/drawer';
import loadingImg from '../assets/loading.svg';
import colors from '../utils/colors';
import { getAllClients, getAllClientsMails, getCuotasDebtors, getPieDebtors } from '../database/clients';
import { Tag } from '@mui/icons-material';
import axios from 'axios';
import Swal from 'sweetalert2';
import loadingSVG from '../assets/loading.svg';
import formatRUT from '../utils/format_rut';
import validateRUT from '../utils/validate_rut';

export default function Emails() {

    const [selectType, setSelectType] = useState('all');
    const [selectComuna, setSelectComuna] = useState(0);
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const [clientsByType, setClientsByType] = useState([]);
    const [communes, setCommunes] = useState([])
    const [selectedClients, setSelectedClients] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersCommune, setUsersCommune] = useState([]);
    const itemsPerPage = 8;
    const [allSelected, setAllSelected] = useState(false);
    const [searchRut, setSearhRut] = useState("");
    const [title, setTitle] = useState("")
    const [buttonText, setButtonText] = useState("")
    const [buttonLink, setButtonLink] = useState("")
    const [body, setBody] = useState("")


    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleToggle = (client) => {
        const isSelected = selectedClients.some(selectedClient => selectedClient.emailEnterprise === client.emailEnterprise);
        let newChecked = [];

        if (isSelected) {
            newChecked = selectedClients.filter(selectedClient => selectedClient.emailEnterprise !== client.emailEnterprise);
        } else {
            newChecked = [...selectedClients, client];
        }

        setSelectedClients(newChecked);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // Función para normalizar las comunas
    const normalizeString = (str) => {
        return str
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""); // Elimina los diacríticos (tildes)
    };

    const handleSelectComunaChange = (e) => {
        setSelectedClients([])
        if (e.target.value === 0) {
            setCurrentPage(1)
            setSelectComuna(0);
            setUsersCommune([]);
            return;
        } else {
            setAllSelected(false);
            setCurrentPage(1)
            const selectedValue = e.target.value;
            setSelectComuna(selectedValue);
            setUsersCommune(clientsByType.filter(client => client.comuneEnterprise === selectedValue))
            console.log("COMUNA", selectedValue);
        }
    };



    useEffect(() => {
        async function getData() {
            setLoading(true);
            setClientsByType([]);
            const responseClients = selectType === 'all' ? await getAllClientsMails() : selectType === 'pie' ? await getPieDebtors() : await getCuotasDebtors();
            setClientsByType(responseClients);

            const communesSet = responseClients.reduce((acc, client) => {
                if (client.comuneEnterprise) {
                    const normalizedComuna = client.comuneEnterprise;
                    if (!acc.has(normalizedComuna)) {
                        acc.add(normalizedComuna);
                    }
                }
                return acc;
            }, new Set());

            const sortedCommunes = [...communesSet].sort((a, b) => a.localeCompare(b));
            setCommunes(sortedCommunes);

            setLoading(false);
        }

        getData();
    }, [selectType]);

    // useEffect(() => {
    //     if (selectComuna === 0) {
    //         setUsersCommune(clientsByType);
    //     } else {
    //         setUsersCommune(clientsByType.filter(client => client.comuneEnterprise === communes[selectComuna - 1]));
    //     }
    //     console.log(usersCommune);
    // }, [selectComuna]);

    const paginatedClients = clientsByType.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const paginatedUsersCommune = usersCommune.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    // const sendMails = async () => {
    //     console.log(title, body, buttonText, buttonLink);
    //     if (selectedClients.length === 0) {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Clientes faltantes',
    //             text: 'Debes seleccionar al menos un cliente para enviarle el correo.'
    //         })
    //     } else {
    //         if (title.length === 0) {
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Título faltante',
    //                 text: 'Debes ingresar un título para el correo.'
    //             })
    //         } else if (body.length === 0) {
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Mensaje faltante',
    //                 text: 'Debes ingresar un mensaje para el cuerpo del correo.'
    //             })
    //         } else if (buttonText.length > 0 && buttonLink.length === 0) {
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Enlace faltante',
    //                 text: 'Si quieres agregar un botón debes agregar el enlace donde llevará al cliente.'
    //             })
    //         } else if (buttonText.length === 0 && buttonLink.length > 0) {
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Texto de botón faltante',
    //                 text: 'Si quieres agregar un botón debes agregar el texto que llevará el botón. Por ejemplo: "Entra aquí" | "Ir a pagar"'
    //             })
    //         } else {
    //             try {
    //                 Swal.fire({
    //                     title: 'Enviando correos',
    //                     text: 'Por favor espere...',
    //                     imageUrl: loadingSVG,
    //                     imageWidth: 300,
    //                     imageHeight: 300,
    //                     showConfirmButton: false,
    //                     allowOutsideClick: false,
    //                     allowEscapeKey: false,
    //                     allowEnterKey: false,
    //                 });
    //                 // const responseMails = await axios.post('http://127.0.0.1:5001/maxipos-dbd94/us-central1/sendEmailBrevo', {
    //                 const responseMails = await axios.post('https://us-central1-maxipos-dbd94.cloudfunctions.net/sendEmailBrevo', {
    //                     emails: selectedClients.map(client => client.emailEnterprise),
    //                     subject: title,
    //                     body: body,
    //                     buttonText,
    //                     buttonLink
    //                 });
    //                 if (responseMails.status === 200) {
    //                     Swal.fire({
    //                         icon: 'success',
    //                         title: 'Correos enviados',
    //                         text: 'Los correos han sido enviados exitosamente'
    //                     }).then(() => {
    //                         setSelectedClients([]);
    //                         setAllSelected(false)
    //                         setTitle("");
    //                         setBody("");
    //                         setButtonText("");
    //                         setButtonLink("");
    //                     });
    //                 } else {
    //                     Swal.fire({
    //                         icon: 'error',
    //                         title: 'Error al enviar correos',
    //                         text: 'Ocurrió un error al enviar los correos, por favor intente de nuevo'
    //                     });
    //                 }
    //             } catch (error) {
    //                 Swal.fire({
    //                     icon: 'error',
    //                     title: 'Error',
    //                     text: 'Ha ocurrido un error. Inténtalo nuevamente.'
    //                 })
    //             }
    //         }
    //     }
    // }

    const sendMails = async () => {
        console.log(title, body, buttonText, buttonLink);
        if (selectedClients.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Clientes faltantes',
                text: 'Debes seleccionar al menos un cliente para enviarle el correo.'
            });
            return;
        }

        if (title.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Título faltante',
                text: 'Debes ingresar un título para el correo.'
            });
            return;
        }

        if (body.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Mensaje faltante',
                text: 'Debes ingresar un mensaje para el cuerpo del correo.'
            });
            return;
        }

        if (buttonText.length > 0 && buttonLink.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Enlace faltante',
                text: 'Si quieres agregar un botón debes agregar el enlace donde llevará al cliente.'
            });
            return;
        }

        if (buttonText.length === 0 && buttonLink.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Texto de botón faltante',
                text: 'Si quieres agregar un botón debes agregar el texto que llevará el botón. Por ejemplo: "Entra aquí" | "Ir a pagar"'
            });
            return;
        }

        try {
            Swal.fire({
                title: 'Enviando correos',
                text: 'Por favor espere...',
                imageUrl: loadingSVG,
                imageWidth: 300,
                imageHeight: 300,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
            });

            // Función para dividir el array en subarrays de tamaño máximo
            const chunkArray = (array, size) => {
                const chunkedArr = [];
                for (let i = 0; i < array.length; i += size) {
                    chunkedArr.push(array.slice(i, i + size));
                }
                return chunkedArr;
            };

            const emailBatches = chunkArray(selectedClients, 90);

            // Enviar cada lote de correos
            for (const batch of emailBatches) {
                // const responseMails = await axios.post('http://127.0.0.1:5001/maxipos-dbd94/us-central1/sendEmailBrevo', {
                const responseMails = await axios.post('https://us-central1-maxipos-dbd94.cloudfunctions.net/sendEmailBrevo', {
                    emails: batch.map(client => client.emailEnterprise),
                    subject: title,
                    body: body,
                    buttonText,
                    buttonLink
                });

                if (responseMails.status !== 200) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al enviar correos',
                        text: 'Ocurrió un error al enviar los correos, por favor intente de nuevo'
                    });
                    return;
                }
            }

            Swal.fire({
                icon: 'success',
                title: 'Correos enviados',
                text: 'Los correos han sido enviados exitosamente'
            }).then(() => {
                setSelectedClients([]);
                setAllSelected(false);
                setTitle("");
                setBody("");
                setButtonText("");
                setButtonLink("");
            });
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Ha ocurrido un error. Inténtalo nuevamente.'
            });
        }
    };


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'start',
            height: '100vh',
            width: '100vw',
            backgroundColor: colors.background,
        }}>
            <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'emails'} />
            {loading ? (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}>
                    <img src={loadingImg} alt="loading" style={{ width: '100px', height: '100px' }} />
                    <p>Cargando...</p>
                </Box>
            ) : (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center',
                }}>
                    <h2 style={{
                        color: colors.green,
                    }}>Envío de correos masivos</h2>
                    <Box width={'80%'}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: '20px',
                        }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '50%',
                            }}>
                                <label style={{
                                    paddingBottom: '10px',
                                }}>Enviar correo a</label>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}>
                                    <Select
                                        value={selectType}
                                        onChange={(e) => setSelectType(e.target.value)}
                                        sx={{
                                            width: '30%',
                                            height: '70%'
                                        }}
                                    >
                                        <MenuItem value={'all'}>Todos</MenuItem>
                                        <MenuItem value={'debtors'}>Deudores</MenuItem>
                                        <MenuItem value={'pie'}>Deudores pie</MenuItem>
                                    </Select>
                                    <Select
                                        value={selectComuna}
                                        onChange={handleSelectComunaChange}
                                        sx={{
                                            ml: 1,
                                            width: '40%',
                                            height: '70%'
                                        }}
                                    >
                                        <MenuItem
                                            value={0}
                                        >Selecciona una comuna</MenuItem>
                                        {
                                            communes.map((comuna, index) => (
                                                <MenuItem key={index} value={comuna}>{comuna}</MenuItem>
                                            ))
                                        }

                                    </Select>
                                    {/* <TextField
                                        sx={{
                                            ml: 1,
                                            width: '30%',
                                            height: '70%',
                                            boxSizing: 'border-box',
                                            '& .MuiInputBase-root': {
                                                height: '100%',
                                                boxSizing: 'border-box'
                                            },
                                            '& .MuiInputBase-input': {
                                                height: '100%',
                                                padding: '10px 12px'
                                            }
                                        }}
                                        placeholder='Busca por rut'
                                        value={searchRut}
                                    // onChange={(e) => {
                                    //     const tempRut = formatRUT(e.target.value);
                                    //     if (validateRUT(tempRut) && tempRut.length > 10) {
                                    //         Swal.fire({
                                    //             title: "Wena"
                                    //         })
                                    //     }else{
                                    //         setSearhRut(formatRUT(e.target.value))
                                    //     }
                                    // }}

                                    /> */}
                                </Box>

                                {
                                    usersCommune.length > 0 ? (
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <label>
                                                {selectedClients.length} clientes seleccionados de {usersCommune.length}
                                            </label>

                                            <Button
                                                onClick={() => {
                                                    setAllSelected(!allSelected)
                                                    if (allSelected) {
                                                        setSelectedClients([])
                                                    } else {
                                                        setSelectedClients(usersCommune)
                                                    }
                                                }}
                                                sx={{
                                                    padding: '0px',
                                                    margin: '0px',
                                                    textTransform: 'none',
                                                    color: colors.green
                                                }}
                                            >
                                                {
                                                    allSelected ? 'Desmarcar todo' : 'Seleccionar todos'
                                                }
                                            </Button>
                                        </div>
                                    ) : (
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}>
                                            <label>
                                                {selectedClients.length} clientes seleccionados de {clientsByType.length}
                                            </label>
                                            <Button
                                                onClick={() => {
                                                    setAllSelected(!allSelected)
                                                    if (allSelected) {
                                                        setSelectedClients([])
                                                    } else {
                                                        setSelectedClients(clientsByType)
                                                    }
                                                }}
                                                sx={{
                                                    padding: '0px',
                                                    margin: '0px',
                                                    textTransform: 'none',
                                                    color: colors.green
                                                }}
                                            >
                                                {
                                                    allSelected ? 'Desmarcar todo' : 'Seleccionar todos'
                                                }
                                            </Button>
                                        </div>

                                    )
                                }

                                <List>
                                    {
                                        usersCommune.length > 0 ?
                                            paginatedUsersCommune.map((client) => (
                                                <ListItem
                                                    onClick={() => handleToggle(client)}
                                                    button key={client.id}
                                                    sx={{
                                                        margin: '2px',
                                                        padding: '5px',
                                                        backgroundColor: colors.white,
                                                        border: `1px solid ${colors.border}`,
                                                        borderRadius: '10px'
                                                    }}>
                                                    {selectedClients.some(selectedClient => selectedClient.emailEnterprise === client.emailEnterprise) ? (
                                                        <Avatar sx={{ bgcolor: 'green' }}>
                                                            <CheckIcon />
                                                        </Avatar>
                                                    ) : (
                                                        <Avatar src={client.restaurant} />
                                                    )}
                                                    <ListItemText sx={{
                                                        ml: 1
                                                    }} primary={client.fantasyName != null ? client.fantasyName : client.socialReason} secondary={client.emailEnterprise} />
                                                </ListItem>
                                            ))
                                            :
                                            paginatedClients.map((client) => (
                                                <ListItem
                                                    onClick={() => handleToggle(client)}
                                                    button key={client.id}
                                                    sx={{
                                                        margin: '2px',
                                                        padding: '5px',
                                                        backgroundColor: colors.white,
                                                        border: `1px solid ${colors.border}`,
                                                        borderRadius: '10px'
                                                    }}>
                                                    {selectedClients.some(selectedClient => selectedClient.emailEnterprise === client.emailEnterprise) ? (
                                                        <Avatar sx={{ bgcolor: 'green' }}>
                                                            <CheckIcon />
                                                        </Avatar>
                                                    ) : (
                                                        client.addType === 'old' ? (
                                                            <Avatar>{client.socialReason.substring(0, 1)}</Avatar>
                                                        ) : (
                                                            <Avatar src={client.restaurant} />
                                                        )
                                                    )}
                                                    <ListItemText sx={{
                                                        ml: 1
                                                    }} primary={client.fantasyName === "" ? client.socialReason : client.fantasyName} secondary={normalizeString(client.emailEnterprise)} />
                                                </ListItem>
                                            ))}
                                </List>
                                {
                                    usersCommune.length === 0 ? (
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            marginTop: '20px'

                                        }}>
                                            <Pagination
                                                count={Math.ceil(clientsByType.length / itemsPerPage)}
                                                page={currentPage}
                                                onChange={handlePageChange}
                                            />
                                        </Box>
                                    ) : (
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            marginTop: '20px'

                                        }}>
                                            <Pagination
                                                count={Math.ceil(usersCommune.length / itemsPerPage)}
                                                page={currentPage}
                                                onChange={handlePageChange}
                                            />
                                        </Box>
                                    )
                                }
                            </Box>




                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '45%',
                                alignItems: 'center',
                            }}>
                                <label style={{
                                    width: '100%',
                                    paddingBottom: '10px',
                                }}>Nuevo correo</label>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    backgroundColor: colors.white,
                                    border: `1px solid ${colors.border}`,
                                    padding: '10px',
                                    borderRadius: '10px',
                                }}>
                                    {/* {selectedClients.length > 0 && (

                                    )} */}
                                    <label>Mensaje principal</label>
                                    <TextField
                                        placeholder="Breve texto que resume el contenido"
                                        variant="filled"
                                        fullWidth
                                        onChange={(e) => {
                                            setTitle(e.target.value)
                                        }}
                                        value={title}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Tag sx={{
                                                        color: colors.black
                                                    }} />
                                                </InputAdornment>
                                            ),
                                            disableUnderline: true,
                                            sx: {
                                                display: 'flex',
                                                alignItems: 'center',
                                                backgroundColor: colors.background,
                                                borderRadius: '10px',
                                                padding: '0 10px',
                                                '& .MuiFilledInput-root': {
                                                    backgroundColor: 'inherit',
                                                    borderRadius: 'inherit',
                                                    padding: '10px 12px',
                                                },
                                                '& .MuiFilledInput-input': {
                                                    padding: '10px 12px',
                                                },

                                                '& .MuiInputAdornment-root': {
                                                    marginRight: '8px',
                                                    marginTop: 'auto',
                                                    marginBottom: '15px',
                                                },
                                            },
                                        }}
                                        sx={{
                                            marginBottom: '10px',
                                            '& .MuiFilledInput-root': {
                                                backgroundColor: colors.background,
                                                borderRadius: '10px',
                                            },
                                        }}
                                    />
                                    <label>Agregar botón</label>
                                    <Box sx={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                                        <TextField
                                            placeholder="Texto (opcional)"
                                            variant="filled"
                                            fullWidth
                                            value={buttonText}
                                            onChange={(e) => {
                                                setButtonText(e.target.value)
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Tag sx={{
                                                            color: colors.black
                                                        }} />
                                                    </InputAdornment>
                                                ),
                                                disableUnderline: true,
                                                sx: {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    backgroundColor: colors.background,
                                                    borderRadius: '10px',
                                                    padding: '0 10px',
                                                    '& .MuiFilledInput-root': {
                                                        backgroundColor: 'inherit',
                                                        borderRadius: 'inherit',
                                                        padding: '10px 12px',
                                                    },
                                                    '& .MuiFilledInput-input': {
                                                        padding: '10px 12px',
                                                    },

                                                    '& .MuiInputAdornment-root': {
                                                        marginRight: '8px',
                                                        marginTop: 'auto',
                                                        marginBottom: '15px',
                                                    },
                                                },
                                            }}
                                            sx={{
                                                marginBottom: '10px',
                                                '& .MuiFilledInput-root': {
                                                    backgroundColor: colors.background,
                                                    borderRadius: '10px',
                                                },
                                            }}
                                        />
                                        <TextField
                                            placeholder="Enlace (opcional)"
                                            variant="filled"
                                            fullWidth
                                            onChange={(e) => {
                                                setButtonLink(e.target.value)
                                            }}
                                            value={buttonLink}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Tag sx={{
                                                            color: colors.black
                                                        }} />
                                                    </InputAdornment>
                                                ),
                                                disableUnderline: true, // Quita la línea de abajo
                                                sx: {
                                                    display: 'flex',
                                                    alignItems: 'center', // Alinea el contenido verticalmente
                                                    backgroundColor: colors.background, // Cambia el color de fondo según tus necesidades
                                                    borderRadius: '10px', // Ajusta el radio del borde
                                                    padding: '0 10px', // Ajusta el padding para centrar el contenido
                                                    '& .MuiFilledInput-root': {
                                                        backgroundColor: 'inherit', // Asegura que el color de fondo se aplique correctamente
                                                        borderRadius: 'inherit', // Aplica el radio del borde correctamente
                                                        padding: '10px 12px', // Ajusta el padding para centrar el contenido
                                                    },
                                                    '& .MuiFilledInput-input': {
                                                        padding: '10px 12px', // Ajusta el padding para centrar el contenido
                                                    },

                                                    '& .MuiInputAdornment-root': {
                                                        marginRight: '8px', // Espacio entre el icono y el texto
                                                        marginTop: 'auto', // Centra verticalmente
                                                        marginBottom: '15px', // Centra verticalmente
                                                    },
                                                },
                                            }}
                                            sx={{
                                                marginBottom: '10px',
                                                '& .MuiFilledInput-root': {
                                                    backgroundColor: colors.background, // Cambia el color de fondo según tus necesidades
                                                    borderRadius: '10px', // Ajusta el radio del borde
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <label>Cuerpo del Correo</label>
                                        <TextField
                                            placeholder="Cuerpo del correo"
                                            variant="filled"
                                            fullWidth
                                            onChange={(e) => {
                                                setBody(e.target.value)
                                            }}
                                            value={body}
                                            multiline
                                            InputProps={{
                                                style: {
                                                    paddingTop: '10px',
                                                    display: 'flex',
                                                    alignItems: 'flex-start',
                                                    minHeight: '150px',
                                                },
                                                disableUnderline: true,
                                                sx: {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    backgroundColor: colors.background,
                                                    borderRadius: '10px',
                                                    padding: '0 10px',
                                                    '& .MuiFilledInput-root': {
                                                        minHeight: '150px',
                                                        backgroundColor: 'inherit',
                                                        borderRadius: 'inherit',
                                                        padding: '10px 12px',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        minHeight: '150px',
                                                    },
                                                    '& .MuiInputBase-input': {
                                                        display: 'flex',
                                                        alignItems: 'flex-start',
                                                    },
                                                    '& .MuiFilledInput-input': {
                                                        padding: '10px 12px',
                                                    },

                                                    '& .MuiInputAdornment-root': {
                                                        marginRight: '8px',
                                                        marginTop: 'auto',
                                                        marginBottom: '15px',
                                                    },
                                                },
                                            }}
                                            sx={{
                                                marginBottom: '10px',
                                                '& .MuiFilledInput-root': {
                                                    backgroundColor: colors.background,
                                                    borderRadius: '10px',
                                                },
                                                '& .MuiInputBase-root': {
                                                    minHeight: '150px',
                                                },
                                                '& .MuiInputBase-input': {
                                                    display: 'flex',
                                                    alignItems: 'flex-start',
                                                },
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Button
                                    onClick={() => { sendMails(); }}
                                    variant="contained"
                                    sx={{
                                        width: '100%',
                                        backgroundColor: colors.green,
                                        marginTop: '20px', // Color de fondo personalizado
                                        borderRadius: '8px', // Borde redondeado
                                        minHeight: '50px', // Altura mínima
                                        '&:hover': {
                                            backgroundColor: colors.greenBackground, // Color de fondo al pasar el ratón
                                        }
                                    }}
                                >
                                    Enviar Email
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </div>
    );
}
