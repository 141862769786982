import { Avatar, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, ListItem, ListItemIcon, ListItemText, MenuItem, Select, styled, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import colors from '../utils/colors'
import { ArrowBackIos, ArrowBackIosNew, ArrowForwardIos, AttachMoneyOutlined, Close, DocumentScanner, FileOpenOutlined, Folder, FolderOutlined, FolderRounded, HomeOutlined, Notes, Person, PersonOutline, Wallet } from '@mui/icons-material'
import { capitalize, capitalizeWords } from '../utils/capitalize'
import { disableClient, getUserById, updateClient } from '../database/clients'
import Swal from 'sweetalert2'
import CustomTextField from './textfield'
import RutTextField from './textfield_rut'
import ModalPay from './modal_pay'
import loadingSVG from '../assets/loading.svg'
import { getAllSellers } from '../database/sellers'
import formatRUT from '../utils/format_rut'

const CustomFormControl = styled(FormControl)(({ theme }) => ({
    marginTop: theme.spacing(2),
    '& .MuiInputLabel-root': {
        color: theme.palette.text.primary,
    },
    '& .MuiSelect-select': {
        padding: theme.spacing(2),
        borderRadius: '12px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)', // Sombra ligera
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.divider,
            borderRadius: '12px', // Borde más redondeado
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main,
        },
    },
}));

export default function ModalViewClient({ id, data, isOpen, onClose, nameSeller, isPays, reloadData }) {
    const [typeView, setTypeView] = useState('default')

    //Data enterprise
    const [rutEnterprise, setRutEnterprise] = useState('')
    const [socialReason, setSocialReason] = useState('')
    const [fantasyName, setFantasyName] = useState('')
    const [addressEnterprise, setAddressEnterprise] = useState('')
    const [phoneEnterprise, setPhoneEnterprise] = useState('')
    const [emailEnterprise, setEmailEnterprise] = useState('')
    const [communeEnterprise, setCommuneEnterprise] = useState('')


    //Represent data
    const [rutRepresent, setRutRepresent] = useState('')
    const [nameRepresent, setNameRepresent] = useState('')
    const [emailRepresent, setEmailRepresent] = useState('')
    const [phoneRepresent, setPhoneRepresent] = useState('')
    const [addressRepresent, setAddressRepresent] = useState('')

    //Bank data
    const [titular, setTitular] = useState('')
    const [bank, setBank] = useState('')
    const [accountType, setAccountType] = useState('')
    const [accountNumber, setAccountNumber] = useState('')

    //Conditions
    const [averageMonthly, setAverageMonthly] = useState('')
    const [credit, setCredit] = useState('')
    const [debit, setDebit] = useState('')

    //Files
    const [imgMain, setImgMain] = useState('')
    const [imgFrontDni, setImgFrontDni] = useState('')
    const [imgBackDni, setImgBackDni] = useState('')
    const [imgSignature, setImgSignature] = useState('')

    const [openPays, setOpenPays] = useState(false)

    // const myId = useSelector((state) => state.auth.idUser)

    const [sellers, setSellers] = useState([])
    const [selectedSeller, setSelectedSeller] = useState(null)

    const [loading, setLoading] = useState(false)

    const handleSellerChange = (selectedOption) => {
        setSelectedSeller(sellers.find((el) => el.value === selectedOption.target.value))
    }

    useEffect(() => {
        async function getData() {
            setLoading(true);
            console.log("AAA");
            const res = await getAllSellers();
            const sellers = res.map((el) => ({
                value: el.id,
                label: el.name
            }));
            setSellers(sellers.sort((a, b) => a.label.localeCompare(b.label)));
            setSelectedSeller(sellers.find((el) => el.value === data.idSeller))
            if (data) {
                setRutEnterprise(data.rutEnterprise || '');
                setSocialReason(data.socialReason || '');
                setFantasyName(data.fantasyName || '');
                setAddressEnterprise(data.addressEnterprise || '');
                setPhoneEnterprise(data.phoneEnterprise || '');
                setEmailEnterprise(data.emailEnterprise || '');
                setCommuneEnterprise(data.communeEnterprise || '');
                setRutRepresent(data.rutRepresent || '');
                setNameRepresent(data.nameRepresent || '');
                setEmailRepresent(data.emailRepresent || '');
                setPhoneRepresent(data.phoneRepresent || '');
                setAddressRepresent(data.addressRepresent || '');
                setTitular(data.titular || '');
                setBank(data.bank || '');
                setAccountType(data.accountType || '');
                setAccountNumber(data.accountNumber || '');
                setAverageMonthly(data.monthlyAverage || '');
                setCredit(data.credit || '');
                setDebit(data.debit || '');
                setImgMain(data.imgMain || '');
                setImgFrontDni(data.imgFrontDni || '');
                setImgBackDni(data.imgBackDni || '');
                setImgSignature(data.imgSignature || '');
            }
            setLoading(false);
        }

        getData();
    }, []);

    const deactivate = async () => {
        Swal.fire({
            icon: 'warning',
            text: '¿Estás seguro que deseas desactivar a este cliente?',
            title: "Advertencia",
            confirmButtonText: "Desactivar",
            showCancelButton: true,
            cancelButtonText: "Cancelar"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await disableClient(id);
                if (res == true) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Se ha desactivado correctamente el cliente.',
                        title: "Realizado",
                        confirmButtonText: "Aceptar"
                    }).then((val) => {
                        onClose();
                        Swal.close();
                        reloadData()
                    })
                    
                }
            }
        })
    }


    const saveData = async () => {
        try {
            Swal.fire({
                imageUrl: loadingSVG,
                imageWidth: 300,
                imageHeight: 300,
                title: "Guardando datos...",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });

            const updatedFields = {
                idSeller: selectedSeller.value,
                rutEnterprise,
                socialReason,
                fantasyName,
                addressEnterprise,
                phoneEnterprise,
                emailEnterprise,
                comuneEnterprise: communeEnterprise,
                rutRepresent,
                nameRepresent,
                emailRepresent,
                phoneRepresent,
                addressRepresent,
                accountMain: titular,
                accountBank: bank,
                accountType: accountType,
                accountNumber: accountNumber,
                monthlyAverage: averageMonthly,
                credit,
                debit,
                imgMain,
                imgFrontDni,
                imgBackDni,
                imgSignature,
            };

            const success = await updateClient(id, updatedFields);

            if (success) {
                await Swal.fire({
                    title: 'Realizado',
                    icon: 'success',
                    text: 'Se han guardado los nuevos datos.',
                    confirmButtonText: 'Aceptar'
                });
                onClose();
                reloadData();
            } else {
                throw new Error('Error al guardar los cambios');
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: 'Error',
                icon: 'error',
                text: 'No se han podido actualizar los datos. Inténtalo nuevamente.'
            });
        }
    };

    const verificationStatus = data.clientVerification ?? 'pending';

    const statusText = verificationStatus === 'accepted'
        ? '* Verificado'
        : verificationStatus === 'declined'
            ? '* Rechazado'
            : '* En espera de verificación';

    const statusColor = verificationStatus === 'accepted'
        ? colors.green
        : verificationStatus === 'declined'
            ? colors.red
            : colors.orange;


    return (
        <>
            <Dialog
                sx={{
                    zIndex: 1
                }}
                PaperProps={{
                    style: {
                        borderRadius: '20px',
                        boxShadow: '0px 0px 20px #0000001A',
                        backgroundColor: colors.background
                    }
                }}
                fullWidth={true}
                maxWidth={"xl"}
                onClose={onClose}
                open={isOpen}>
                <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {
                        typeView === 'default' && (
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ fontFamily: 'Nunito', fontWeight: 'bold', color: colors.green }}
                                gutterBottom
                            >
                                Viendo cliente
                            </Typography>
                        )
                    }
                    {
                        typeView !== 'default' && (
                            <ArrowBackIosNew onClick={() => setTypeView('default')} style={{
                                cursor: "pointer", border:
                                    `1px solid ${colors.green}`, borderRadius: '10px',
                                padding: '8px'
                            }} />
                        )
                    }
                    <Close onClick={onClose} style={{ cursor: "pointer" }} />
                </DialogTitle>
                <DialogContent>
                    <Container sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        {
                            data.addType === 'old' ?
                                (
                                    <Avatar sx={{ backgroundColor: colors.green, width: '100px', height: '100px' }}>{
                                        data.socialReason.substring(0, 1).toUpperCase()
                                    }</Avatar>
                                ) : (
                                    <Avatar onClick={
                                        () => {
                                            window.open(data.restaurant, '_blank')
                                        }
                                    } src={data.restaurant} sx={{ width: '100px', height: '100px', cursor: 'pointer' }} />
                                )
                        }
                        <Typography variant="h6" sx={{ fontFamily: 'Nunito', fontWeight: 'bold', color: colors.green }}>{data.fantasyName ? capitalize(data.fantasyName) : capitalize(data.socialReason)}</Typography>
                        <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>{capitalize(data.addressEnterprise)}</Typography>
                    </Container>
                    {
                        typeView === 'default' && (
                            <Box>
                                <Box width={'100%'} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '20px'
                                }}>
                                    <Box width={'80%'} sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between'
                                    }}>
                                        <ListItem>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    fontFamily: 'Nunito',
                                                    color: statusColor,
                                                    marginTop: '20px',
                                                    fontSize: '18px'
                                                }}
                                                secondaryTypographyProps={{
                                                    fontFamily: 'Nunito',
                                                    color: 'grey',
                                                    fontSize: '16px'
                                                }}
                                                primary={statusText}
                                                secondary={
                                                    (<Button
                                                        onClick={() => {
                                                            deactivate()
                                                        }}
                                                        sx={{
                                                            textTransform: 'none',
                                                            color: 'red'
                                                        }}>
                                                        Desactivar
                                                    </Button>)
                                                }

                                            />
                                        </ListItem>
                                        {
                                            !loading ? (
                                                <CustomFormControl variant="outlined" fullWidth>
                                                    <InputLabel id="seller-select-label">Vendedor a cargo</InputLabel>
                                                    <Select
                                                        labelId="seller-select-label"
                                                        value={selectedSeller == null ? data.idSeller : selectedSeller.value}
                                                        onChange={handleSellerChange}
                                                        label="Vendedor a cargo"
                                                    >
                                                        {sellers.map((seller) => (
                                                            <MenuItem key={seller.value} value={seller.value}>
                                                                {capitalizeWords(seller.label)}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </CustomFormControl>
                                            ) : (
                                                <span>Cargando...</span>
                                            )
                                        }
                                        {/* <ListItem>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    fontFamily: 'Nunito',
                                                    color: statusColor,
                                                    marginTop: '20px',
                                                    fontSize: '18px'
                                                }}
                                                secondaryTypographyProps={{
                                                    fontFamily: 'Nunito',
                                                    color: 'grey',
                                                    fontSize: '16px'
                                                }}
                                                primary={statusText}
                                                secondary={`Vendedor a cargo: ${nameSeller ?? '...'}`} />
                                        </ListItem> */}
                                        {isPays && (
                                            <Button
                                                onClick={() => {
                                                    setOpenPays(true)
                                                }}
                                                variant='text' sx={{
                                                    fontFamily: 'Nunito',
                                                    color: colors.green,
                                                    textTransform: 'none',
                                                    width: '200px'
                                                }} >
                                                Ver deudas
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                                <Box width={'100%'} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '20px'
                                }}>
                                    <Box width={'80%'} sx={{
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-around',
                                        alignItems: 'center',
                                        border: '2px solid #1212121F',
                                        borderRadius: '10px',
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            margin: '10px',
                                            alignItems: 'center'
                                        }}>
                                            <Wallet sx={{ color: data.pays ? colors.green : colors.grey }} />
                                            <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Pagos</Typography>
                                        </Box>
                                        <Divider orientation="vertical" flexItem />
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            margin: '10px',
                                            alignItems: 'center'
                                        }}>
                                            <DocumentScanner sx={{ color: data.invoice ? colors.green : colors.grey }} />
                                            <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Boleta</Typography>
                                        </Box>
                                        <Divider orientation="vertical" flexItem />
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            margin: '10px',
                                            alignItems: 'center'
                                        }}>
                                            <FolderRounded sx={{ color: data.bill ? colors.green : colors.grey }} />
                                            <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Factura</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box width={'100%'} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '20px'
                                }}>
                                    <Box width={'80%'} sx={{
                                        display: 'flex',
                                        alignItems: 'start',
                                        flexDirection: 'column',
                                    }}>
                                        <Typography variant="p" sx={{ fontFamily: 'Nunito', color: "grey", marginTop: '20px' }}>Datos de cliente</Typography>
                                        <ListItem
                                            onClick={() => {
                                                if (data.offices.length > 0) {
                                                    setTypeView('sucursales')
                                                } else {
                                                    Swal.fire({
                                                        title: 'No hay sucursales',
                                                        text: 'Este cliente no tiene sucursales registradas',
                                                        icon: 'info',
                                                        confirmButtonText: 'Aceptar'
                                                    })

                                                }
                                            }}
                                            button
                                            sx={{
                                                backgroundColor: 'white',
                                                borderRadius: '10px',
                                                border: '1px solid #1212121F',
                                                marginTop: '5px',
                                                padding: '10px'
                                            }}>
                                            <ListItemIcon>
                                                <HomeOutlined sx={{
                                                    color: colors.grey
                                                }} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Datos de sucursales</Typography>
                                            </ListItemText>
                                            <ListItemIcon>
                                                <ArrowForwardIos sx={{
                                                    fontSize: '20px'
                                                }} />
                                            </ListItemIcon>
                                        </ListItem>
                                        <ListItem
                                            onClick={() => {
                                                setRutEnterprise(formatRUT(data.rutEnterprise))
                                                setSocialReason(data.socialReason)
                                                setFantasyName(data.fantasyName)
                                                setAddressEnterprise(data.addressEnterprise)
                                                setPhoneEnterprise(data.phoneEnterprise)
                                                setEmailEnterprise(data.emailEnterprise)
                                                setCommuneEnterprise(data.comuneEnterprise)
                                                setTypeView('empresa')
                                            }}
                                            button sx={{
                                                backgroundColor: 'white',
                                                borderRadius: '10px',
                                                border: '1px solid #1212121F',
                                                marginTop: '5px',
                                                padding: '10px'
                                            }}>
                                            <ListItemIcon>
                                                <FolderOutlined sx={{
                                                    color: colors.grey
                                                }} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Datos de la empresa</Typography>
                                            </ListItemText>
                                            <ListItemIcon>
                                                <ArrowForwardIos sx={{
                                                    fontSize: '20px'
                                                }} />
                                            </ListItemIcon>
                                        </ListItem>
                                        <ListItem
                                            onClick={() => {
                                                setRutRepresent(formatRUT(data.rutRepresent))
                                                setNameRepresent(data.nameRepresent)
                                                setEmailRepresent(data.emailRepresent)
                                                setPhoneRepresent(data.phoneRepresent)
                                                setAddressRepresent(data.addressRepresent)
                                                setTypeView('represent')
                                            }}
                                            button sx={{
                                                backgroundColor: 'white',
                                                borderRadius: '10px',
                                                border: '1px solid #1212121F',
                                                marginTop: '5px',
                                                padding: '10px'
                                            }}>
                                            <ListItemIcon>
                                                <PersonOutline sx={{
                                                    color: colors.grey
                                                }} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Datos de representante legal</Typography>
                                            </ListItemText>
                                            <ListItemIcon>
                                                <ArrowForwardIos sx={{
                                                    fontSize: '20px'
                                                }} />
                                            </ListItemIcon>
                                        </ListItem>
                                        <ListItem
                                            onClick={() => {
                                                setTitular(data.accountMain)
                                                setBank(data.accountBank)
                                                setAccountType(data.accountType)
                                                setAccountNumber(data.accountNumber)
                                                setTypeView('bank')
                                            }}
                                            button sx={{
                                                backgroundColor: 'white',
                                                borderRadius: '10px',
                                                border: '1px solid #1212121F',
                                                marginTop: '5px',
                                                padding: '10px'
                                            }}>
                                            <ListItemIcon>
                                                <AttachMoneyOutlined sx={{
                                                    color: colors.grey
                                                }} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Datos bancarios</Typography>
                                            </ListItemText>
                                            <ListItemIcon>
                                                <ArrowForwardIos sx={{
                                                    fontSize: '20px'
                                                }} />
                                            </ListItemIcon>
                                        </ListItem>
                                        <ListItem
                                            onClick={() => {
                                                setAverageMonthly(data.monthlyAverage)
                                                setCredit(data.credit)
                                                setDebit(data.debit)
                                                setTypeView('conditions')
                                            }}
                                            button sx={{
                                                backgroundColor: 'white',
                                                borderRadius: '10px',
                                                border: '1px solid #1212121F',
                                                marginTop: '5px',
                                                padding: '10px'
                                            }}>
                                            <ListItemIcon>
                                                <Notes sx={{
                                                    color: colors.grey
                                                }} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Condiciones</Typography>
                                            </ListItemText>
                                            <ListItemIcon>
                                                <ArrowForwardIos sx={{
                                                    fontSize: '20px'
                                                }} />
                                            </ListItemIcon>
                                        </ListItem>
                                        {
                                            data.addType !== 'old' && (
                                                <ListItem
                                                    onClick={() => {
                                                        setImgMain(data.restaurant)
                                                        setImgFrontDni(data.frontDni)
                                                        setImgBackDni(data.backDni)
                                                        setImgSignature(data.signature)
                                                        setTypeView('files')
                                                    }}
                                                    button sx={{
                                                        backgroundColor: 'white',
                                                        borderRadius: '10px',
                                                        border: '1px solid #1212121F',
                                                        marginTop: '5px',
                                                        padding: '10px'
                                                    }}>
                                                    <ListItemIcon>
                                                        <FileOpenOutlined sx={{
                                                            color: colors.grey
                                                        }} />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Archivos adjuntos</Typography>
                                                    </ListItemText>
                                                    <ListItemIcon>
                                                        <ArrowForwardIos sx={{
                                                            fontSize: '20px'
                                                        }} />
                                                    </ListItemIcon>
                                                </ListItem>
                                            )
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        )
                    }
                    {
                        typeView === 'sucursales' && (
                            <Box width={'100%'} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '20px'
                            }}>
                                <Box width={'80%'} sx={{}}>
                                    {
                                        data.offices.map((office, index) => (
                                            index == 0 ?
                                                <ListItem key={index} sx={{
                                                    backgroundColor: 'white',
                                                    borderRadius: '10px',
                                                    border: '1px solid #1212121F',
                                                    marginTop: '5px',
                                                    padding: '10px'
                                                }}>
                                                    <ListItemIcon>
                                                        <HomeOutlined sx={{
                                                            color: colors.grey
                                                        }} />
                                                    </ListItemIcon>
                                                    <ListItemText primaryTypographyProps={{
                                                        fontFamily: 'Nunito'
                                                    }} primary={office.address} secondary={'Casa matriz'} />
                                                </ListItem>
                                                :
                                                <ListItem key={index} sx={{
                                                    backgroundColor: 'white',
                                                    borderRadius: '10px',
                                                    border: '1px solid #1212121F',
                                                    marginTop: '5px',
                                                    padding: '10px'
                                                }}>
                                                    <ListItemIcon>
                                                        <HomeOutlined sx={{
                                                            color: colors.grey
                                                        }} />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>{office.address}</Typography>
                                                    </ListItemText>
                                                </ListItem>
                                        ))
                                    }
                                </Box>
                            </Box>

                        )
                    }
                    {
                        typeView === 'empresa' && (
                            <Box width={'100%'} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '20px'
                            }}>
                                <Box width={'80%'} sx={{}}>
                                    <RutTextField
                                        label={'Rut'}
                                        fullWidth
                                        controller={{
                                            value: rutEnterprise,
                                            setValue: setRutEnterprise
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={data.addType === 'old' ? true : false}
                                    />
                                    <CustomTextField
                                        label={'Razón social'}
                                        fullWidth
                                        controller={{
                                            value: socialReason,
                                            setValue: setSocialReason
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={data.addType === 'old' ? true : false}
                                    />
                                    <CustomTextField
                                        label={'Nombre de fantasía'}
                                        fullWidth
                                        controller={{
                                            value: fantasyName,
                                            setValue: setFantasyName
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={data.addType === 'old' ? true : false}
                                    />
                                    <CustomTextField
                                        label={'Dirección'}
                                        fullWidth
                                        controller={{
                                            value: addressEnterprise,
                                            setValue: setAddressEnterprise
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={data.addType === 'old' ? true : false}
                                    />
                                    <CustomTextField
                                        label={'Comuna'}
                                        fullWidth
                                        controller={{
                                            value: communeEnterprise,
                                            setValue: setCommuneEnterprise
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={data.addType === 'old' ? true : false}
                                    />
                                    <CustomTextField
                                        label={'Teléfono'}
                                        fullWidth
                                        controller={{
                                            value: phoneEnterprise,
                                            setValue: setPhoneEnterprise
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={data.addType === 'old' ? true : false}
                                    />
                                    <CustomTextField
                                        label={'Email'}
                                        fullWidth
                                        controller={{
                                            value: emailEnterprise,
                                            setValue: setEmailEnterprise
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={data.addType === 'old' ? true : false}
                                    />
                                </Box>
                            </Box>
                        )
                    }
                    {
                        typeView === 'represent' && (
                            <Box width={'100%'} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '20px'
                            }}>
                                <Box width={'80%'} sx={{}}>
                                    <RutTextField
                                        label={'Rut'}
                                        fullWidth
                                        controller={{
                                            value: rutRepresent,
                                            setValue: setRutRepresent
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={data.addType === 'old' ? true : false}
                                    />
                                    <CustomTextField
                                        label={'Nombre'}
                                        fullWidth
                                        controller={{
                                            value: nameRepresent,
                                            setValue: setNameRepresent
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={true}
                                    />
                                    <CustomTextField
                                        label={'Email'}
                                        fullWidth
                                        controller={{
                                            value: emailRepresent,
                                            setValue: setEmailRepresent
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={data.addType === 'old' ? true : false}
                                    />
                                    <CustomTextField
                                        label={'Teléfono'}
                                        fullWidth
                                        controller={{
                                            value: phoneRepresent,
                                            setValue: setPhoneRepresent
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={true}
                                    />
                                    <CustomTextField
                                        label={'Dirección'}
                                        fullWidth
                                        controller={{
                                            value: addressRepresent,
                                            setValue: setAddressRepresent
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={true}
                                    />
                                </Box>
                            </Box>
                        )
                    }
                    {
                        typeView === 'bank' && (
                            <Box width={'100%'} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '20px'
                            }}>
                                <Box width={'80%'} sx={{}}>
                                    <CustomTextField
                                        label={'Titular'}
                                        fullWidth
                                        controller={{
                                            value: titular,
                                            setValue: setTitular
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={true}
                                    />
                                    <CustomTextField
                                        label={'Banco'}
                                        fullWidth
                                        controller={{
                                            value: bank,
                                            setValue: setBank
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={true}
                                    />
                                    <CustomTextField
                                        label={'Tipo de cuenta'}
                                        fullWidth
                                        controller={{
                                            value: accountType,
                                            setValue: setAccountType
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={true}
                                    />
                                    <CustomTextField
                                        label={'Número de cuenta'}
                                        fullWidth
                                        controller={{
                                            value: accountNumber,
                                            setValue: setAccountNumber
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={true}
                                    />
                                </Box>
                            </Box>
                        )
                    }
                    {
                        typeView === 'conditions' && (
                            <Box width={'100%'} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '20px'
                            }}>
                                <Box width={'80%'} sx={{}}>
                                    <CustomTextField
                                        label={'Promedio mensual'}
                                        fullWidth
                                        controller={{
                                            value: averageMonthly,
                                            setValue: setAverageMonthly
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={data.addType === 'old' ? true : false}
                                    />
                                    <CustomTextField
                                        label={'Crédito'}
                                        fullWidth
                                        controller={{
                                            value: credit,
                                            setValue: setCredit
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={data.addType === 'old' ? true : false}
                                    />
                                    <CustomTextField
                                        label={'Débito'}
                                        fullWidth
                                        controller={{
                                            value: debit,
                                            setValue: setDebit
                                        }}
                                        icon={<Person sx={{ color: colors.grey }} />}
                                        unlocked={data.addType === 'old' ? true : false}
                                    />
                                </Box>
                            </Box>
                        )
                    }
                    {
                        typeView === 'files' && (
                            <Box width={'100%'} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}>
                                <Box width={'80%'} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-evenly'
                                }}>
                                    <Container>
                                        <img src={imgMain} alt="Main" onClick={() => {
                                            window.open(imgMain, '_blank')
                                        }} width={'20%'} style={{
                                            cursor: 'pointer',
                                            padding: '10px'
                                        }} />
                                        <img src={imgFrontDni} onClick={() => {
                                            window.open(imgFrontDni, '_blank')
                                        }} alt="Front" width={'20%'} style={{
                                            padding: '10px',
                                            cursor: 'pointer'
                                        }} />
                                        <img src={imgBackDni} onClick={() => {
                                            window.open(imgBackDni, '_blank')
                                        }} alt="Back" width={'20%'} style={{
                                            padding: '10px',
                                            cursor: 'pointer'
                                        }} />
                                        <img src={imgSignature} onClick={() => {
                                            window.open(imgSignature, '_blank')
                                        }} alt="Signature" width={'20%'} style={{
                                            padding: '10px',
                                            cursor: 'pointer'
                                        }} />
                                    </Container>
                                </Box>
                            </Box>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <Button sx={{
                        fontFamily: 'Nunito',
                        color: 'black',
                        textTransform: 'none'
                    }} onClick={onClose}>Cancelar</Button>
                    <Button
                        onClick={() => {
                            saveData();
                        }}
                        sx={{
                            fontFamily: 'Nunito',
                            fontWeight: 'bold',
                            color: colors.green,
                            textTransform: 'none'
                        }}>Guardar</Button>
                </DialogActions>
            </Dialog >
            {
                openPays && isPays && (
                    <ModalPay data={data} isOpen={openPays} onCloseMain={() => {
                        onClose()
                    }} reloadData={() => {
                        reloadData();
                    }} onClose={() => setOpenPays(false)} />
                )
            }
        </>
    )
}
