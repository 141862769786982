import { Box, Button, Icon, InputAdornment, Snackbar, TextField, Typography, styled } from '@mui/material';
import React, { useState } from 'react';
import colors from '../utils/colors';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Container = styled(Box)(({ theme }) => ({
    padding: '5px 3%',
}));

const Label = styled(Typography)(({ theme }) => ({
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: '11px',
    marginBottom: '5px',
}));

const TextFieldContainer = styled(Box)(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    padding: '10px',
    // cursor: 'pointer'
}));

const formatRut = (rut) => {
    // Remover puntos y guion del RUT
    let cleanRut = rut.replace(/[.-]/g, '');

    // Verificar que el RUT no esté vacío o tenga caracteres inválidos
    if (!cleanRut || isNaN(cleanRut.slice(0, -1)) || (cleanRut.slice(-1) !== 'K' && isNaN(cleanRut.slice(-1)))) {
        return rut;
    }

    // Obtener la parte numérica y el dígito verificador
    const num = cleanRut.slice(0, -1);
    const dv = cleanRut.slice(-1).toUpperCase();

    // Retornar el RUT formateado sin puntos, solo con guion
    return `${num}-${dv}`;
};

const RutTextField = ({ label, controller, icon, unlocked }) => {

    const [snackbarOpen, setSnackbarOpen] = useState(false);


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleCopy = () => {
        setSnackbarOpen(true);
    };

    const handleRutChange = (e) => {
        if (e.target.value.length > 12) {
            return;
        }
        const formattedRut = formatRut(e.target.value);
        controller.setValue(formattedRut);
    };

    return (
        <Container>
            <Label sx={{
                color: 'black',
                fontFamily: 'Nunito'
            }} component="p">{label}</Label>

            {
                unlocked === false ?
                    (<CopyToClipboard text={controller.value} onCopy={handleCopy}>
                        <TextFieldContainer>
                            <TextField
                                maxLength="12"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon>{icon}</Icon>
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true,
                                    inputProps: {
                                        readOnly: true,
                                        style: { cursor: 'pointer' }
                                    }
                                }}
                                sx={{
                                    pointerEvents: unlocked ? 'auto' : 'none',
                                }}
                                placeholder={label}
                                variant="standard"
                                value={controller.value}
                                onChange={handleRutChange}
                            />
                        </TextFieldContainer>
                    </CopyToClipboard>) : (
                        <TextFieldContainer>
                            <TextField
                                maxLength="12"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon>{icon}</Icon>
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true,

                                }}
                                sx={{
                                    pointerEvents: unlocked ? 'auto' : 'none',
                                }}
                                placeholder={label}
                                variant="standard"
                                value={controller.value}
                                onChange={handleRutChange}
                            />
                        </TextFieldContainer>
                    )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        backgroundColor: colors.green,
                        color: 'white',
                        fontFamily: 'Nunito'
                    }
                }}
                message={`Rut copiado al portapapeles`}
                action={
                    <Button color="inherit" size="small" onClick={handleSnackbarClose}>
                        Cerrar
                    </Button>
                }
            />
        </Container>
    );
};

export default RutTextField;
