import { collection, doc, updateDoc, query, where, getDocs, getDoc } from 'firebase/firestore';
import { db } from './config_firebase';
import { getActualEmail } from './values';

// const getPendingRequests = (callback) => {
//   const unsubscribe = onSnapshot(query(
//     collection(db, 'users'),
//     where('status', '==', 'pending')
//   ), (snapshot) => {
//     const pendingRequests = [];
//     snapshot.forEach(doc => {
//       pendingRequests.push({ id: doc.id, ...doc.data() });
//     });
//     callback(pendingRequests);
//   });

//   return unsubscribe;
// };

const getPendingRequests = async () => {
  try {
    const querySnapshot = await getDocs(query(
      collection(db, 'users'),
      where('status', '==', 'pending')
    ));
    const pendingRequests = [];
    querySnapshot.forEach(doc => {
      pendingRequests.push({ id: doc.id, ...doc.data() });
    });
    return pendingRequests;
  } catch (error) {
    console.error('Error getting pending requests:', error);
    return [];
  }
}

const updateStatusRequest = async (id, status) => {
  try {
    const email = await getActualEmail();
    await updateDoc(doc(db, 'users', id), {
      status: status,
      emailOwner: email
    });
    return true;
  } catch (error) {
    console.error('Error updating request status:', error);
    return false;
  }
};

async function payPie(idClient) {
  try {
    const clientRef = doc(db, 'users', idClient);
    const clientDoc = await getDoc(clientRef);

    if (!clientDoc.exists()) {
      console.error('Client not found');
      return false;
    }

    const clientData = clientDoc.data();
    const dataPays = clientData.dataPays;

    dataPays.piePayed = 'payed';
    dataPays.datePiePayed = new Date();

    await updateDoc(clientRef, { dataPays });

    return true;
  } catch (e) {
    console.error('Error updating pie: ', e);
    return false;
  }

}

async function payQuota(idClient) {
  try {
    const clientRef = doc(db, 'users', idClient);
    const clientDoc = await getDoc(clientRef);

    if (!clientDoc.exists()) {
      console.error('Client not found');
      return false;
    }

    const clientData = clientDoc.data();
    const dataPays = clientData.dataPays;
    const cuotas = dataPays.cuotas;

    // Encuentra la primera cuota pendiente
    let firstPendingQuota;
    for (let quota of cuotas) {
      if (quota.status === 'pending') {
        firstPendingQuota = quota;
        break;
      }
    }

    // Si se encontró una cuota pendiente, actualiza sus valores
    if (firstPendingQuota) {
      firstPendingQuota.status = 'payed';
      firstPendingQuota.datePayed = new Date();

      // Si la cuota pagada es la última, actualiza el estado general a 'payed'
      if (cuotas[cuotas.length - 1] === firstPendingQuota) {
        dataPays.status = 'payed';
      } else {
        dataPays.status = 'pending';
      }

      await updateDoc(clientRef, { dataPays });

      return true;
    } else {
      // No se encontraron cuotas pendientes, el estado ya está pagado
      return false;
    }
  } catch (e) {
    console.error('Error updating quota: ', e);
    return false;
  }
}

export { getPendingRequests, updateStatusRequest, payQuota, payPie };