import { Avatar, Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import colors from '../utils/colors'
import { AccountBalanceTwoTone, ArrowBackIos, ArrowBackIosNew, ArrowForwardIos, AttachMoneyOutlined, Close, CreditCardTwoTone, DocumentScanner, FileOpenOutlined, Folder, FolderOutlined, FolderRounded, HomeOutlined, MailTwoTone, MapTwoTone, Money, Notes, Numbers, Percent, Person, PersonOutline, PersonTwoTone, PhoneTwoTone, Wallet } from '@mui/icons-material'
import { capitalize } from '../utils/capitalize'
import { getUserById } from '../database/clients'
import Swal from 'sweetalert2'
import CustomTextField from './textfield'
import RutTextField from './textfield_rut'
import { updateStatusRequest } from '../database/requests'
import formatRUT from '../utils/format_rut'

export default function ModalAcceptClient({ id, data, isOpen, onClose, nameSeller, reloadData }) {

    const [acceptOffices, setAcceptOffices] = useState(false)
    const [acceptEnterprise, setAcceptEnterprise] = useState(false)
    const [acceptRepresent, setAcceptRepresent] = useState(false)
    const [acceptBank, setAcceptBank] = useState(false)
    const [acceptConditions, setAcceptConditions] = useState(false)
    const [acceptFiles, setAcceptFiles] = useState(false)

    const [typeView, setTypeView] = useState('default')

    //Data enterprise
    const [rutEnterprise, setRutEnterprise] = useState('')
    const [socialReason, setSocialReason] = useState('')
    const [fantasyName, setFantasyName] = useState('')
    const [addressEnterprise, setAddressEnterprise] = useState('')
    const [phoneEnterprise, setPhoneEnterprise] = useState('')
    const [emailEnterprise, setEmailEnterprise] = useState('')
    const [communeEnterprise, setCommuneEnterprise] = useState('')


    //Represent data
    const [rutRepresent, setRutRepresent] = useState('')
    const [nameRepresent, setNameRepresent] = useState('')
    const [emailRepresent, setEmailRepresent] = useState('')
    const [phoneRepresent, setPhoneRepresent] = useState('')
    const [addressRepresent, setAddressRepresent] = useState('')

    //Bank data
    const [titular, setTitular] = useState('')
    const [bank, setBank] = useState('')
    const [accountType, setAccountType] = useState('')
    const [accountNumber, setAccountNumber] = useState('')

    //Conditions
    const [averageMonthly, setAverageMonthly] = useState('')
    const [credit, setCredit] = useState('')
    const [debit, setDebit] = useState('')

    //Files
    const [imgMain, setImgMain] = useState('')
    const [imgFrontDni, setImgFrontDni] = useState('')
    const [imgBackDni, setImgBackDni] = useState('')
    const [imgSignature, setImgSignature] = useState('')

    const verificationStatus = data.clientVerification;

    const statusText = verificationStatus === 'accepted'
        ? '* Verificado'
        : verificationStatus === 'declined'
            ? '* Rechazado'
            : '* En espera de verificación';

    const statusColor = verificationStatus === 'accepted'
        ? colors.green
        : verificationStatus === 'declined'
            ? colors.red
            : colors.orange;


    const approveClient = async (idUser) => {
        try {
            Swal.fire({
                title: '¿Estás seguro?',
                text: 'Estás a punto de aprobar este cliente',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: colors.green,
                cancelButtonColor: colors.red
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await updateStatusRequest(idUser, 'accepted');
                    if (response) {
                        Swal.fire('Cliente aprobado', '', 'success')
                        onClose()
                        reloadData();
                    } else {
                        Swal.fire('Error', 'No se pudo aprobar el cliente', 'error')
                    }
                } else {
                    Swal.fire('Aprobación cancelada', '', 'info')
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    const declineClient = async (idClient) => {
        try {
            Swal.fire({
                title: '¿Estás seguro?',
                text: 'Estás a punto de rechazar este cliente',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: colors.red,
                cancelButtonColor: colors.grey
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await updateStatusRequest(idClient, 'declined');
                    if (response) {
                        Swal.fire('Cliente rechazado', '', 'success')
                        onClose()
                        reloadData();
                    } else {
                        Swal.fire('Error', 'No se pudo rechazar el cliente', 'error')
                    }
                } else {
                    Swal.fire('Rechazo cancelado', '', 'info')
                }
            })
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <Dialog
            sx={{
                zIndex: 1
            }}
            PaperProps={{
                style: {
                    borderRadius: '20px',
                    boxShadow: '0px 0px 20px #0000001A',
                    backgroundColor: colors.background
                }
            }}
            fullWidth={true}
            maxWidth={"xl"}
            onClose={onClose}
            open={isOpen}>
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {
                    typeView === 'default' && (
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ fontFamily: 'Nunito', fontWeight: 'bold', color: colors.green }}
                            gutterBottom
                        >
                            Viendo cliente
                        </Typography>
                    )
                }
                {
                    typeView !== 'default' && (
                        <ArrowBackIosNew onClick={() => setTypeView('default')} style={{
                            cursor: "pointer", border:
                                `1px solid ${colors.green}`, borderRadius: '10px',
                            padding: '8px'
                        }} />
                    )
                }
                <Close onClick={onClose} style={{ cursor: "pointer" }} />
            </DialogTitle>
            <DialogContent>
                <Container sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Avatar onClick={
                        () => {
                            window.open(data.restaurant, '_blank')
                        }
                    } src={data.restaurant} sx={{ width: '100px', height: '100px', cursor: 'pointer' }} />
                    <Typography variant="h6" sx={{ fontFamily: 'Nunito', fontWeight: 'bold', color: colors.green }}>{data.fantasyName ? capitalize(data.fantasyName) : capitalize(data.socialReason)}</Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>{capitalize(data.addressEnterprise)}</Typography>
                </Container>
                {
                    typeView === 'default' && (
                        <Box>
                            <Box width={'100%'} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '20px'
                            }}>
                                <Box width={'80%'} sx={{
                                    display: 'flex',
                                    alignItems: 'start',
                                    flexDirection: 'column',
                                }}>
                                    <Typography variant="p" sx={{ fontFamily: 'Nunito', color: statusColor, marginTop: '20px' }}>{statusText}</Typography>
                                    <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Vendedor a cargo: {nameSeller ?? '...'}</Typography>
                                </Box></Box>
                            <Box width={'100%'} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '20px'
                            }}>
                                <Box width={'80%'} sx={{
                                    backgroundColor: 'white',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    border: '2px solid #1212121F',
                                    borderRadius: '10px',
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '10px',
                                        alignItems: 'center'
                                    }}>
                                        <Wallet sx={{ color: data.pays ? colors.green : colors.grey }} />
                                        <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Pagos</Typography>
                                    </Box>
                                    <Divider orientation="vertical" flexItem />
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '10px',
                                        alignItems: 'center'
                                    }}>
                                        <DocumentScanner sx={{ color: data.invoice ? colors.green : colors.grey }} />
                                        <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Boleta</Typography>
                                    </Box>
                                    <Divider orientation="vertical" flexItem />
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '10px',
                                        alignItems: 'center'
                                    }}>
                                        <FolderRounded sx={{ color: data.bill ? colors.green : colors.grey }} />
                                        <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Factura</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box width={'100%'} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '20px'
                            }}>
                                <Box width={'80%'} sx={{
                                    display: 'flex',
                                    alignItems: 'start',
                                    flexDirection: 'column',
                                }}>
                                    <Typography variant="p" sx={{ fontFamily: 'Nunito', color: "grey", marginTop: '20px' }}>Datos de cliente</Typography>
                                    <ListItem
                                        onClick={() => {
                                            if (data.offices.length > 0) {
                                                setTypeView('sucursales')
                                            } else {
                                                Swal.fire({
                                                    title: 'No hay sucursales',
                                                    text: 'Este cliente no tiene sucursales registradas',
                                                    icon: 'info',
                                                    confirmButtonText: 'Aceptar'
                                                })

                                            }
                                        }}
                                        button
                                        sx={{
                                            backgroundColor: 'white',
                                            borderRadius: '10px',
                                            border: '1px solid #1212121F',
                                            marginTop: '5px',
                                            padding: '10px'
                                        }}>
                                        <ListItemIcon>
                                            <Checkbox
                                                // checked={acceptOffices}
                                                checked={acceptOffices}
                                                sx={{
                                                    "&.Mui-checked": {
                                                        color: colors.green,
                                                    },
                                                    color: colors.green
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setAcceptOffices(!acceptOffices)
                                                    console.log(acceptOffices, 'acceptOffices', acceptBank, 'acceptBank', acceptConditions, 'acceptConditions', acceptEnterprise, 'acceptEnterprise', acceptFiles, 'acceptFiles', acceptRepresent, 'acceptRepresent');
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Datos de sucursales</Typography>
                                        </ListItemText>
                                        <ListItemIcon>
                                            <ArrowForwardIos sx={{
                                                fontSize: '20px'
                                            }} />
                                        </ListItemIcon>
                                    </ListItem>
                                    <ListItem
                                        onClick={() => {
                                            setRutEnterprise(formatRUT(data.rutEnterprise))
                                            setSocialReason(data.socialReason)
                                            setFantasyName(data.fantasyName)
                                            setAddressEnterprise(data.addressEnterprise)
                                            setPhoneEnterprise(data.phoneEnterprise)
                                            setEmailEnterprise(data.emailEnterprise)
                                            setCommuneEnterprise(data.comuneEnterprise)
                                            setTypeView('empresa')
                                        }}
                                        button sx={{
                                            backgroundColor: 'white',
                                            borderRadius: '10px',
                                            border: '1px solid #1212121F',
                                            marginTop: '5px',
                                            padding: '10px'
                                        }}>
                                        <ListItemIcon>
                                            <Checkbox
                                                // checked={acceptEnterprise}
                                                checked={acceptEnterprise}
                                                sx={{
                                                    "&.Mui-checked": {
                                                        color: colors.green,
                                                    },
                                                    color: colors.green
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setAcceptEnterprise(!acceptEnterprise)
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Datos de la empresa</Typography>
                                        </ListItemText>
                                        <ListItemIcon>
                                            <ArrowForwardIos sx={{
                                                fontSize: '20px'
                                            }} />
                                        </ListItemIcon>
                                    </ListItem>
                                    <ListItem
                                        onClick={() => {
                                            console.log("Este es el rut", formatRUT(data.rutRepresent));

                                            setRutRepresent(formatRUT(data.rutRepresent))
                                            setNameRepresent(data.nameRepresent)
                                            setEmailRepresent(data.emailRepresent)
                                            setPhoneRepresent(data.phoneRepresent)
                                            setAddressRepresent(data.addressRepresent)
                                            setTypeView('represent')
                                        }}
                                        button sx={{
                                            backgroundColor: 'white',
                                            borderRadius: '10px',
                                            border: '1px solid #1212121F',
                                            marginTop: '5px',
                                            padding: '10px'
                                        }}>
                                        <ListItemIcon>
                                            <Checkbox
                                                // checked={acceptRepresent}
                                                checked={acceptRepresent}
                                                sx={{
                                                    "&.Mui-checked": {
                                                        color: colors.green,
                                                    },
                                                    color: colors.green
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setAcceptRepresent(!acceptRepresent)
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Datos de representante legal</Typography>
                                        </ListItemText>
                                        <ListItemIcon>
                                            <ArrowForwardIos sx={{
                                                fontSize: '20px'
                                            }} />
                                        </ListItemIcon>
                                    </ListItem>
                                    <ListItem
                                        onClick={() => {
                                            setTitular(data.accountMain)
                                            setBank(data.accountBank)
                                            setAccountType(data.accountType)
                                            setAccountNumber(data.accountNumber)
                                            setTypeView('bank')
                                        }}
                                        button sx={{
                                            backgroundColor: 'white',
                                            borderRadius: '10px',
                                            border: '1px solid #1212121F',
                                            marginTop: '5px',
                                            padding: '10px'
                                        }}>
                                        <ListItemIcon>
                                            <Checkbox
                                                // checked={acceptBank}
                                                checked={acceptBank}
                                                sx={{
                                                    "&.Mui-checked": {
                                                        color: colors.green,
                                                    },
                                                    color: colors.green
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setAcceptBank(!acceptBank)
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Datos bancarios</Typography>
                                        </ListItemText>
                                        <ListItemIcon>
                                            <ArrowForwardIos sx={{
                                                fontSize: '20px'
                                            }} />
                                        </ListItemIcon>
                                    </ListItem>
                                    <ListItem
                                        onClick={() => {
                                            setAverageMonthly(data.monthlyAverage)
                                            setCredit(data.credit)
                                            setDebit(data.debit)
                                            setTypeView('conditions')
                                        }}
                                        button sx={{
                                            backgroundColor: 'white',
                                            borderRadius: '10px',
                                            border: '1px solid #1212121F',
                                            marginTop: '5px',
                                            padding: '10px'
                                        }}>
                                        <ListItemIcon>
                                            <Checkbox
                                                // checked={acceptConditions}
                                                checked={acceptConditions}
                                                sx={{
                                                    "&.Mui-checked": {
                                                        color: colors.green,
                                                    },
                                                    color: colors.green
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setAcceptConditions(!acceptConditions)
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Condiciones</Typography>
                                        </ListItemText>
                                        <ListItemIcon>
                                            <ArrowForwardIos sx={{
                                                fontSize: '20px'
                                            }} />
                                        </ListItemIcon>
                                    </ListItem>
                                    <ListItem
                                        onClick={() => {
                                            setImgMain(data.restaurant)
                                            setImgFrontDni(data.frontDni)
                                            setImgBackDni(data.backDni)
                                            setImgSignature(data.signature)
                                            setTypeView('files')
                                        }}
                                        button sx={{
                                            backgroundColor: 'white',
                                            borderRadius: '10px',
                                            border: '1px solid #1212121F',
                                            marginTop: '5px',
                                            padding: '10px'
                                        }}>
                                        <ListItemIcon>
                                            <Checkbox
                                                // checked={acceptFiles}
                                                checked={acceptFiles}
                                                sx={{
                                                    "&.Mui-checked": {
                                                        color: colors.green,
                                                    },
                                                    color: colors.green
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    setAcceptFiles(!acceptFiles)
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>Archivos adjuntos</Typography>
                                        </ListItemText>
                                        <ListItemIcon>
                                            <ArrowForwardIos sx={{
                                                fontSize: '20px'
                                            }} />
                                        </ListItemIcon>
                                    </ListItem>
                                </Box>
                            </Box>
                        </Box>
                    )
                }
                {
                    typeView === 'sucursales' && (
                        <Box width={'100%'} sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '20px'
                        }}>
                            <Box width={'80%'} sx={{}}>
                                {
                                    data.offices.map((office, index) => (
                                        index == 0 ?
                                            <ListItem key={index} sx={{
                                                backgroundColor: 'white',
                                                borderRadius: '10px',
                                                border: '1px solid #1212121F',
                                                marginTop: '5px',
                                                padding: '10px'
                                            }}>
                                                <ListItemIcon>
                                                    <HomeOutlined sx={{
                                                        color: colors.grey
                                                    }} />
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{
                                                    fontFamily: 'Nunito'
                                                }} primary={office.address} secondary={'Casa matriz'} />
                                            </ListItem>
                                            :
                                            <ListItem key={index} sx={{
                                                backgroundColor: 'white',
                                                borderRadius: '10px',
                                                border: '1px solid #1212121F',
                                                marginTop: '5px',
                                                padding: '10px'
                                            }}>
                                                <ListItemIcon>
                                                    <HomeOutlined sx={{
                                                        color: colors.grey
                                                    }} />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Typography variant="body1" sx={{ fontFamily: 'Nunito', color: "grey" }}>{office.address}</Typography>
                                                </ListItemText>
                                            </ListItem>
                                    ))
                                }
                            </Box>
                        </Box>

                    )
                }
                {
                    typeView === 'empresa' && (
                        <Box width={'100%'} sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '20px'
                        }}>
                            <Box width={'80%'} sx={{}}>
                                <RutTextField
                                    label={'Rut'}
                                    fullWidth
                                    controller={{
                                        value: rutEnterprise,
                                        setValue: setRutEnterprise
                                    }}
                                    icon={<PersonTwoTone sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                                <CustomTextField
                                    label={'Razón social'}
                                    fullWidth
                                    controller={{
                                        value: socialReason,
                                        setValue: setSocialReason
                                    }}
                                    icon={<PersonTwoTone sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                                <CustomTextField
                                    label={'Nombre de fantasía'}
                                    fullWidth
                                    controller={{
                                        value: fantasyName,
                                        setValue: setFantasyName
                                    }}
                                    icon={<PersonTwoTone sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                                <CustomTextField
                                    label={'Dirección'}
                                    fullWidth
                                    controller={{
                                        value: addressEnterprise,
                                        setValue: setAddressEnterprise
                                    }}
                                    icon={<MapTwoTone sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                                <CustomTextField
                                    label={'Comuna'}
                                    fullWidth
                                    controller={{
                                        value: communeEnterprise,
                                        setValue: setCommuneEnterprise
                                    }}
                                    icon={<MapTwoTone sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                                <CustomTextField
                                    label={'Teléfono'}
                                    fullWidth
                                    controller={{
                                        value: phoneEnterprise,
                                        setValue: setPhoneEnterprise
                                    }}
                                    icon={<PhoneTwoTone sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                                <CustomTextField
                                    label={'Email'}
                                    fullWidth
                                    controller={{
                                        value: emailEnterprise,
                                        setValue: setEmailEnterprise
                                    }}
                                    icon={<MailTwoTone sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                            </Box>
                        </Box>
                    )
                }
                {
                    typeView === 'represent' && (
                        <Box width={'100%'} sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '20px'
                        }}>
                            <Box width={'80%'} sx={{}}>
                                <RutTextField
                                    label={'Rut'}
                                    fullWidth
                                    controller={{
                                        value: rutRepresent,
                                        setValue: setRutRepresent
                                    }}
                                    icon={<PersonTwoTone sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                                <CustomTextField
                                    label={'Nombre'}
                                    fullWidth
                                    controller={{
                                        value: nameRepresent,
                                        setValue: setNameRepresent
                                    }}
                                    icon={<PersonTwoTone sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                                <CustomTextField
                                    label={'Email'}
                                    fullWidth
                                    controller={{
                                        value: emailRepresent,
                                        setValue: setEmailRepresent
                                    }}
                                    icon={<MailTwoTone sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                                <CustomTextField
                                    label={'Teléfono'}
                                    fullWidth
                                    controller={{
                                        value: phoneRepresent,
                                        setValue: setPhoneRepresent
                                    }}
                                    icon={<PhoneTwoTone sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                                <CustomTextField
                                    label={'Dirección'}
                                    fullWidth
                                    controller={{
                                        value: addressRepresent,
                                        setValue: setAddressRepresent
                                    }}
                                    icon={<MapTwoTone sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                            </Box>
                        </Box>
                    )
                }
                {
                    typeView === 'bank' && (
                        <Box width={'100%'} sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '20px'
                        }}>
                            <Box width={'80%'} sx={{}}>
                                <CustomTextField
                                    label={'Titular'}
                                    fullWidth
                                    controller={{
                                        value: titular,
                                        setValue: setTitular
                                    }}
                                    icon={<PersonTwoTone sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                                <CustomTextField
                                    label={'Banco'}
                                    fullWidth
                                    controller={{
                                        value: bank,
                                        setValue: setBank
                                    }}
                                    icon={<AccountBalanceTwoTone sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                                <CustomTextField
                                    label={'Tipo de cuenta'}
                                    fullWidth
                                    controller={{
                                        value: accountType,
                                        setValue: setAccountType
                                    }}
                                    icon={<CreditCardTwoTone sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                                <CustomTextField
                                    label={'Número de cuenta'}
                                    fullWidth
                                    controller={{
                                        value: accountNumber,
                                        setValue: setAccountNumber
                                    }}
                                    icon={<Numbers sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                            </Box>
                        </Box>
                    )
                }
                {
                    typeView === 'conditions' && (
                        <Box width={'100%'} sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '20px'
                        }}>
                            <Box width={'80%'} sx={{}}>
                                <CustomTextField
                                    label={'Promedio mensual'}
                                    fullWidth
                                    controller={{
                                        value: averageMonthly,
                                        setValue: setAverageMonthly
                                    }}
                                    icon={<Percent sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                                <CustomTextField
                                    label={'Crédito'}
                                    fullWidth
                                    controller={{
                                        value: credit,
                                        setValue: setCredit
                                    }}
                                    icon={<CreditCardTwoTone sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                                <CustomTextField
                                    label={'Débito'}
                                    fullWidth
                                    controller={{
                                        value: debit,
                                        setValue: setDebit
                                    }}
                                    icon={<Money sx={{ color: colors.black }} />}
                                    unlocked={false}
                                />
                            </Box>
                        </Box>
                    )
                }
                {
                    typeView === 'files' && (
                        <Box width={'100%'} sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}>
                            <Box width={'80%'} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-evenly'
                            }}>
                                <Container>
                                    <img src={imgMain} alt="Main" onClick={() => {
                                        window.open(imgMain, '_blank')
                                    }} width={'20%'} style={{
                                        cursor: 'pointer',
                                        padding: '10px'
                                    }} />
                                    <img src={imgFrontDni} onClick={() => {
                                        window.open(imgFrontDni, '_blank')
                                    }} alt="Front" width={'20%'} style={{
                                        padding: '10px',
                                        cursor: 'pointer'
                                    }} />
                                    <img src={imgBackDni} onClick={() => {
                                        window.open(imgBackDni, '_blank')
                                    }} alt="Back" width={'20%'} style={{
                                        padding: '10px',
                                        cursor: 'pointer'
                                    }} />
                                    <img src={imgSignature} onClick={() => {
                                        window.open(imgSignature, '_blank')
                                    }} alt="Signature" width={'20%'} style={{
                                        padding: '10px',
                                        cursor: 'pointer'
                                    }} />
                                </Container>
                            </Box>
                        </Box>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button sx={{
                    fontFamily: 'Nunito',
                    color: 'black',
                    textTransform: 'none'
                }} onClick={onClose}>Cancelar</Button>
                <Button sx={{
                    fontFamily: 'Nunito',
                    color: colors.red,
                    textTransform: 'none'
                }} onClick={
                    () => {
                        declineClient(data.id,)
                    }
                }>Rechazar</Button>
                {
                    acceptOffices && acceptEnterprise && acceptRepresent && acceptBank && acceptConditions && acceptFiles && (
                        <div>
                            <Button
                                onClick={() => {
                                    console.log(data, 'idClient');
                                    approveClient(
                                        data.id,
                                    )
                                }}
                                sx={{
                                    fontFamily: 'Nunito',
                                    color: colors.green,
                                    textTransform: 'none'
                                }}>Aprobar</Button>
                        </div>

                    )
                }
            </DialogActions>
        </Dialog>
    )
}
