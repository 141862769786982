import { doc, getDoc, collection, updateDoc } from 'firebase/firestore';
import { db } from './config_firebase';

const getValuePos = async () => {
  try {
    const docRef = doc(db, 'values', 'riVHL7GlXLz0Zf2VS7oo');
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().posValue.toString();
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting value for POS:', error);
    return null;
  }
};

const getActualEmail = async () => {
  try {
    const docRef = doc(db, 'values', 'riVHL7GlXLz0Zf2VS7oo');
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().email.toString();
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting value for Email:', error);
    return null;
  }
};

const updateActualEmail = async (value) => {
  if (value === undefined || value === null) {
    console.log("Retorna null");
    
    return false;
  } else {
    try {
      const docRef = doc(db, 'values', 'riVHL7GlXLz0Zf2VS7oo');
      await updateDoc(docRef, { email: value });
      return true;
    } catch (error) {
      console.error('Error updating value for Email:', error);
      return false;
    }
  }
};


const updateValuePos = async (value) => {
  if (isNaN(value)) {
    console.error('Invalid value for POS:', value);
    return false;
  } else {
    try {
      const docRef = doc(db, 'values', 'riVHL7GlXLz0Zf2VS7oo');
      await updateDoc(docRef, { posValue: parseInt(value) });
      return true;
    } catch (error) {
      console.error('Error updating value for POS:', error);
      return false;
    }
  }
};

export { getValuePos, updateValuePos, updateActualEmail, getActualEmail };