import { Close, Mail, Numbers, Person, Phone } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import colors from '../utils/colors'
import CustomTextField from './textfield'
import validateRUT from '../utils/validate_rut'
import { createSeller } from '../database/sellers'
import Swal from 'sweetalert2'
import loadingSVG from '../assets/loading.svg'
import RutTextField from './textfield_rut'
import { useSelector } from 'react-redux'

export default function ModalNewSeller({ isOpen, onClose }) {
    const myId = useSelector(state => state.auth.idUser)
    const [name, setName] = useState('')
    const [rut, setRut] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const saveNewSeller = async () => {
        console.log(name, rut, email, phone)
        try {
            Swal.fire({
                imageUrl: loadingSVG,
                imageWidth: 300,
                imageHeight: 300,
                title: "Creando vendedor...",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
            const response = await createSeller({
                name,
                rut,
                email,
                phone,
                idAdmin: myId
            });
            if (response) {
                onClose();
                Swal.fire({
                    icon: "success",
                    confirmButtonText: "Aceptar",
                    title: "Vendedor creado",
                    text: "El vendedor ha sido creado exitosamente.",
                });
            } else {
                Swal.fire({
                    icon: "error",
                    confirmButtonText: "Aceptar",
                    title: "Oops...",
                    text: "Ocurrió un error al crear el vendedor. Inténtalo nuevamente.",
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            onClose={onClose}
            open={isOpen}>
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontFamily: 'Nunito', fontWeight: 'bold', color: colors.green }}
                    gutterBottom
                >
                    Crear vendedor
                </Typography>
                <Close onClick={onClose} style={{ cursor: "pointer" }} />
            </DialogTitle>
            <DialogContent>
                <CustomTextField label="Nombre"
                    controller={{
                        value: name,
                        setValue: setName
                    }}
                    unlocked={true}
                    fullWidth
                    icon={<Person />} />
                <RutTextField label="Rut"
                    controller={{
                        value: rut,
                        setValue: setRut
                    }}
                    unlocked={true}
                    fullWidth
                    icon={<Numbers />} />
                <CustomTextField label="Email"
                    controller={{
                        value: email,
                        setValue: setEmail
                    }}
                    unlocked={true}
                    fullWidth
                    icon={<Mail />} />
                <CustomTextField label="Teléfono"
                    controller={{
                        value: phone,
                        setValue: setPhone
                    }}
                    unlocked={true}
                    fullWidth
                    icon={<Phone />} />
            </DialogContent>
            <DialogActions>
                <Button sx={{
                    fontFamily: 'Nunito',
                    color: 'black',
                    textTransform: 'none'
                }} onClick={onClose}>Cancelar</Button>
                <Button
                    onClick={saveNewSeller}
                    sx={{
                        fontFamily: 'Nunito',
                        fontWeight: 'bold',
                        color: colors.green,
                        textTransform: 'none'
                    }}>Guardar</Button>
            </DialogActions>
        </Dialog>
    )
}
