import React, { useEffect, useState } from 'react'
import ReusableDrawer from '../components/drawer'
import { Avatar, Box, Fab, ListItem, ListItemAvatar, ListItemIcon, ListItemText, MenuItem, Pagination, Select, Typography } from '@mui/material';
import colors from '../utils/colors';
import { Add, ArrowForwardIos, Print, Settings, SupportAgent } from '@mui/icons-material';
import ModalNewRequest from '../components/modal_new_request';
import { getPendingSupportRequests } from '../database/support_queries';
import loadingSVG from '../assets/loading.svg'
import Swal from 'sweetalert2';
import { capitalize } from '../utils/capitalize';

export default function SupportPage() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [allRequests, setAllRequests] = useState([]);

    const [selectedTypeSupport, setSelectedTypeSupport] = useState('all')
    const [selectedFrom, setselectedFrom] = useState('all')

    const [page, setPage] = useState(1);
    const itemsPerPage = 5;

    const typesSupport = [
        { value: 'all', label: 'Todas las solicitudes' },
        { value: 'paper', label: 'Solicitudes de papel' },
        { value: 'service', label: 'Solicitudes de servicio técnico' }
    ];

    const fromList = [
        { value: 'all', label: 'Todas las solicitudes' },
        { value: 'seller', label: 'Solicitudes asignadas a vendedor' },
        { value: 'support', label: 'Solicitudes asignadas a soporte' }
    ]

    const handleOpen = () => {
        setOpenModal(true);
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const getData = async () => {
        Swal.fire({
            imageUrl: loadingSVG,
            imageWidth: 300,
            imageHeight: 300,
            title: "Buscando solicitudes pendientes...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
        const requests = await getPendingSupportRequests();
        setAllRequests(requests);
        Swal.close();
    };

    useEffect(() => {
        getData();
    }, []);


    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedRequests = allRequests.slice(startIndex, endIndex);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'start',
            height: '100vh',
            width: '100%',
        }}>
            <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'support'} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
            }}>
                <h2 style={{
                    color: colors.green,
                }}>Todas las solicitudes</h2>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '90%'
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mr: 2
                    }}>
                        <label>Selecciona el tipo de solicitud</label>
                        <Select
                            value={selectedTypeSupport}
                            // onChange={(e) => setSelectType(e.target.value)}
                            sx={{
                                height: '70%',
                            }}
                        >
                            {/* <MenuItem
                                value={0}
                            >Selecciona el tipo de solicitud</MenuItem> */}
                            {
                                typesSupport.map((comuna, index) => (
                                    <MenuItem key={index} value={comuna.value}>{comuna.label}</MenuItem>
                                ))
                            }
                        </Select>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <label>Selecciona a quién se le asignó la solicitud</label>
                        <Select
                            value={selectedFrom}
                            // onChange={handleSelectComunaChange}
                            sx={{
                                ml: 1,
                                height: '70%'
                            }}
                        >
                            {/* <MenuItem
                                value={0}
                            >Selecciona una comuna</MenuItem> */}
                            {
                                fromList.map((comuna, index) => (
                                    <MenuItem key={index} value={comuna.value}>{comuna.label}</MenuItem>
                                ))
                            }

                        </Select>
                    </Box>
                </Box>
                <br />
                {paginatedRequests.map((request) => (
                    <ListItem
                        onClick={() => { }}
                        key={request.id} sx={{
                            cursor: 'pointer',
                            margin: '2px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '90%',
                            backgroundColor: '#f5f5f5',
                            borderRadius: '10px',
                            border: '1px solid #1212121F',
                        }}>
                        <ListItemAvatar>
                            {request.addType !== 'old' ? (
                                <img src={request.imageClient} alt="Logo" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                            ) : (
                                <Avatar sx={{ backgroundColor: colors.green }}>
                                    {request.socialReason.substring(0, 1)}
                                </Avatar>
                            )}
                        </ListItemAvatar>
                        <ListItemText
                            sx={{ ml: 1 }}
                            primaryTypographyProps={{
                                fontFamily: 'Nunito'
                            }}
                            secondaryTypographyProps={{
                                fontFamily: 'Nunito'
                            }}
                            primary={`Solicitud de: ${capitalize(request.nameClient)}`}
                        />
                        <ListItemIcon>
                            {request.type === "paper" ? <Print /> : request.type === 'service' ? <Settings /> : <SupportAgent />}
                        </ListItemIcon>
                    </ListItem>
                ))}

                <Pagination
                    count={Math.ceil(allRequests.length / itemsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    sx={{ mt: 2 }}
                />
            </Box>

            <Fab
                color="primary"
                aria-label="add"
                onClick={handleOpen}
                sx={{ position: 'fixed', bottom: 16, right: 16, borderRadius: '10px', backgroundColor: colors.green }}
            >
                <Add />
            </Fab>

            {openModal && (
                <ModalNewRequest open={openModal} handleClose={() => setOpenModal(false)} />
            )}
        </div>
    );
}