import { doc, getDoc, collection, updateDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from './config_firebase';

const getPendingSupportRequests = async () => {
    try {
      const querySnapshot = await getDocs(query(
        collection(db, 'requests'),
        where('status', '==', 'pending')
      ));
      const pendingRequests = [];
      querySnapshot.forEach(doc => {
        pendingRequests.push({ id: doc.id, ...doc.data() });
      });
      return pendingRequests;
    } catch (error) {
      console.error('Error getting pending requests:', error);
      return [];
    }
  }

  export { getPendingSupportRequests }